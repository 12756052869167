<template>
  <v-tooltip
    bottom
    :open-delay="500"
    color="#000000"
    :disabled="$vuetify.breakpoint.xsOnly"
    :open-on-hover="$vuetify.breakpoint.smAndUp"
  >
    <template #activator="{ on }">
      <div
        :class="{ shake: isShake, 'd-flex': true, noselect: true }"
        :style="{ cursor: 'pointer' }"
        v-on="on"
        @mousedown="$event.stopPropagation()"
        @click="displayEvent()"
      >
        <div
          v-if="isEventStartBeforeDate"
          :style="{
            width: '0px',
            height: '0px',
            'border-top': borderTopAndBottomStyle,
            'border-right': `12px solid ${chipColor}`,
            'border-bottom': borderTopAndBottomStyle,
          }"
        />
        <div
          class="caption"
          :style="{
            'background-color': chipColor,
            width: '100%',
            height: `${height}px`,
            'border-radius': borderRadius,
          }"
        >
          <div
            class="pl-1"
            :style="{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              color: chipTextColor,
            }"
          >
            <span
              v-if="$vuetify.breakpoint.smAndUp"
              class="font-weight-bold pr-1"
            >
              {{ eventTime }}
            </span>
            {{ event.subject }}
          </div>
        </div>
        <div
          v-if="isEventEndAfterDate"
          :style="{
            width: '0px',
            height: '0px',
            'border-top': borderTopAndBottomStyle,
            'border-left': `12px solid ${chipColor}`,
            'border-bottom': borderTopAndBottomStyle,
            'background-color': 'transparent',
          }"
        />
      </div>
    </template>
    {{ getTimeDisplay }}
    <br />
    {{ getEventDetailsDisplay }}
  </v-tooltip>
</template>

<script>
import { isSameDay, format } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    start: {
      type: Date,
      default() {
        return App.helpers.getDateObject();
      },
    },
    end: {
      type: Date,
      default() {
        return App.helpers.getDateObject();
      },
    },
    height: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    ...mapGetters(['normalizedEventTypes', 'preferences']),
    isShake() {
      return false;
      // if (this.event.id) {
      //   return this.$route.query.event === this.event.id;
      // }
      // return this.$route.query.event === this.event.recurrence_id;
    },
    borderTopAndBottomStyle() {
      return `${this.height / 2}px solid transparent`;
    },
    chipColor() {
      if (this.event.event_type_id === null) {
        return '#CFD8DC';
      }
      return this.normalizedEventTypes[this.event.event_type_id].color;
    },
    chipTextColor() {
      return this.$root.getTextColor(this.chipColor);
    },
    borderRadius() {
      const isBeforeDate = this.isEventStartBeforeDate;
      const isAfterDate = this.isEventEndAfterDate;
      const topLeft = isBeforeDate ? '0px' : '5px';
      const topRight = isAfterDate ? '0px' : '5px';
      const bottomRight = isAfterDate ? '0px' : '5px';
      const bottomLeft = isBeforeDate ? '0px' : '5px';
      return `${topLeft} ${topRight} ${bottomRight} ${bottomLeft}`;
    },
    isEventStartBeforeDate() {
      return this.event.start < `${format(this.start, 'yyyy-MM-dd')} 00:00:00`;
    },
    isEventEndAfterDate() {
      return this.event.end > `${format(this.end, 'yyyy-MM-dd')} 23:59:59`;
    },
    eventTime() {
      if (this.event.all_day === 1) {
        return '';
      }
      const start = this.event.start.slice(11, 16);
      if (this.preferences.is_end_time_shown) {
        const end = this.event.end.slice(11, 16);
        return `${start}~${end}`;
      }
      return start;
    },
    getTimeDisplay() {
      const start = App.helpers.getDateObject(this.event.start);
      const end = App.helpers.getDateObject(this.event.end);
      const allDay = this.event.all_day;
      const startDate = this.$i18n.d(start, 'allDayEvent');
      const endDate = this.$i18n.d(end, 'allDayEvent');
      const startDateTime = this.$i18n.d(start, 'eventTime');
      const endDateTime = this.$i18n.d(end, 'eventTime');
      if (allDay && isSameDay(start, end)) {
        return startDate;
      }
      if (allDay && !isSameDay(start, end)) {
        return `${startDate}~${endDate}`;
      }

      return `${startDateTime} ~ ${endDateTime}`;
    },
    getEventDetailsDisplay() {
      return this.event.subject;
      // <span v-if="!!schedule.place">({{ schedule.place }})</span>
    },
  },
  methods: {
    displayEvent() {
      window.event.stopPropagation();
      this.$store.dispatch('storeClickedEventChipInfo', {
        data: this.event,
        x: window.event.x,
        y: window.event.y,
      });
      this.$emit('close-menu');
    },
  },
};
</script>

<style>
.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.shake {
  animation: shake 0.7s;
  animation-iteration-count: 3;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
