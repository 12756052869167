<template>
  <v-card
    :style="{
      height: '100%',
      'border-width': '0 0 1px 1px',
      'background-color': getCellColor,
    }"
    elevation="0"
    outlined
    tile
    :color="isCellDragSelected ? 'blue-grey lighten-3' : ''"
  >
    <draggable
      :disabled="$vuetify.breakpoint.smAndDown"
      :list="items"
      group="schedules"
      style="height: 100%; width: 100%"
      @mousedown.native="storeDragSelectStartInfo()"
      @mouseover.native="storeDragSelectMoveInfo()"
      @mouseup.native="storeDragSelectEndInfo($event.x, $event.y)"
      @click.native="click($event.x, $event.y)"
      @dblclick.native="
        $store.dispatch('storeDoubleClickInfo', {
          id: rowId,
          date: date,
        })
      "
      @start="startEventDrag"
      @unchoose="$store.dispatch('endEventDrag')"
      @change="change"
    >
      <div
        v-for="(cellItem, index) in items"
        :key="index"
        :style="{
          width: getEventChipWidth(cellItem),
          top: getEventChipTopStartPosition(cellItem),
          position: 'absolute',
          'z-index': '2',
        }"
      >
        <event-chip
          :event="cellItem"
          :start="rowFirstDate"
          :end="rowLastDate"
        />
      </div>
    </draggable>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  differenceInCalendarDays,
  isSaturday,
  isSunday,
  isPast,
  isToday,
  addDays,
  isBefore,
} from 'date-fns';

import draggable from 'vuedraggable';
import EventChip from './EventChip.vue';
import { EVENT_HEIGHT } from './gridConstants';

export default {
  components: {
    draggable,
    EventChip,
  },
  props: {
    cell: {
      type: Object,
      default() {
        return {};
      },
    },
    rowId: {
      type: Number,
      default: 0,
    },
    rowFirstDate: {
      type: Date,
      default() {
        return App.helpers.getDateObject();
      },
    },
    rowLastDate: {
      type: Date,
      default() {
        return App.helpers.getDateObject();
      },
    },
  },
  data() {
    return {
      clicks: 0,
    };
  },
  computed: {
    ...mapGetters([
      'isDragSelect',
      'dragSelectInfo',
      'nationalHolidays',
      'idOfEventBeingDragged',
      'dropInfo',
    ]),
    getCellColor() {
      const date = App.helpers.getDateObject(this.date);
      const isDatePast = isPast(date) && !isToday(date);
      if (isSunday(date) || this.isHoliday) {
        return isDatePast ? 'rgb(215,185,185)' : 'rgb(255,235,238)';
      }
      if (isSaturday(date)) {
        return isDatePast ? 'rgb(194,218,225)' : 'rgb(225, 245, 254)';
      }
      return isDatePast ? 'rgb(249,249,249)' : 'white';
    },
    isHoliday() {
      return this.nationalHolidays.some(
        (holiday) => holiday.date === this.date,
      );
    },
    isCellDragSelected() {
      if (
        this.dragSelectInfo === null ||
        this.rowId !== this.dragSelectInfo.row
      ) {
        return false;
      }
      const { start } = this.dragSelectInfo;
      const { end } = this.dragSelectInfo;
      return (
        (this.date >= end && this.date <= start) ||
        (this.date <= end && this.date >= start)
      );
    },
    date() {
      return this.cell.date;
    },
    items() {
      return this.cell.data;
    },
  },
  methods: {
    getEventChipWidth(event) {
      const cellDate = App.helpers.getDateObject(this.date);
      const eventEnd = this.isEventDropped(event.id)
        ? this.getNewEventEndDate(cellDate, event)
        : App.helpers.getDateObject(event.end);
      const nearestEndDate = isBefore(this.rowLastDate, eventEnd)
        ? this.rowLastDate
        : eventEnd;
      const durationOfEvent = differenceInCalendarDays(
        nearestEndDate,
        cellDate,
      );
      const eventWidth = durationOfEvent * 100.75 + 95;
      return `${eventWidth}%`;
    },
    isEventDropped(eventId) {
      return this.dropInfo !== null && eventId === this.dropInfo.data.id;
    },
    getNewEventEndDate(newStart, event) {
      const oldStart = App.helpers.getDateObject(event.start);
      const oldEnd = App.helpers.getDateObject(event.end);
      const eventDuration = differenceInCalendarDays(oldEnd, oldStart);
      return addDays(newStart, eventDuration);
    },
    getEventChipTopStartPosition(item) {
      let topStartPosition = 0;
      if (this.idOfEventBeingDragged !== null) {
        topStartPosition += 1;
        if (item.id === this.idOfEventBeingDragged.id) {
          return `0px`;
        }
      }
      topStartPosition = (topStartPosition + item.index) * EVENT_HEIGHT;
      return `${topStartPosition}px`;
    },
    storeDragSelectStartInfo() {
      this.$store.dispatch('startDragSelect', {
        row: this.rowId,
        start: this.date,
      });
    },
    storeDragSelectMoveInfo() {
      if (this.isDragSelect) {
        this.$store.dispatch('updateDragSelect', this.date);
      }
    },
    storeDragSelectEndInfo(x, y) {
      if (this.isDragSelect) {
        this.$store.dispatch('endDragSelect');
        const { start } = this.dragSelectInfo;
        const { end } = this.dragSelectInfo;
        if (start === end) {
          return;
        }
        this.storeSelectInfo(x, y, start, end);
      }
    },
    storeSelectInfo(x, y, start, end) {
      [start, end] = start > end ? [end, start] : [start, end];
      this.$store.dispatch('storeSelectInfo', {
        x,
        y,
        row: this.rowId,
        col: { start, end },
      });
    },
    click(x, y) {
      this.clicks += 1;
      setTimeout(() => {
        if (this.clicks === 1) {
          this.$store.dispatch('storeClickInfo', {
            x,
            y,
            row: this.rowId,
            col: this.date,
          });
        }
        this.clicks = 0;
      }, 50);
    },
    startEventDrag({ oldIndex }) {
      const event = this.items[oldIndex];
      this.$store.dispatch('startEventDrag', event.id);
    },
    change(changeEvent) {
      if (changeEvent.added) {
        this.$store.dispatch('storeDropInfo', {
          data: changeEvent.added.element,
          row: this.rowId,
          col: this.date,
        });
      }
    },
  },
};
</script>
