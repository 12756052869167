<template>
  <v-menu
    v-model="isOpen"
    absolute
    :position-x="displayPosition.x"
    :position-y="displayPosition.y"
    :close-on-content-click="false"
  >
    <v-card v-if="isOpen" width="500px">
      <toolbar
        :is-editable="isEditable"
        :is-deletable="isDeletable"
        @copy="copyEvent"
        @edit="editEvent"
        @delete="handleDeleteEvent"
        @close="closeMenu"
      />
      <v-card-text>
        <div class="text-h6 font-weight-bold px-2" style="text-align: justify">
          {{ displayEvent.subject }}
        </div>
        <div class="d-flex justify-space-between align-center pl-2">
          <span class="grey--text text--darken-3">
            {{ time }}
          </span>
          <event-type-chip
            v-if="displayEvent.event_type_id != null"
            :id="displayEvent.event_type_id"
          />
        </div>
        <details-display-element
          v-if="displayEvent.description"
          :icon="'mdi-text'"
          :message="formatDescription(displayEvent.description)"
          :tooltip-text="$t('meetingScheduler.body')"
          :is-html-view="true"
        />
        <details-display-element
          :icon="'mdi-account-tie'"
          :message="getCreatorName"
          :tooltip-text="$t('meetingScheduler.organizer')"
        />
        <details-display-menu
          :icon="'mdi-account-group'"
          :details="getUsersNames"
          color="grey lighten-2"
          :tooltip-text="$t('meetingScheduler.members')"
        />
        <details-display-menu
          icon="mdi-seat"
          :details="getFacilitiesNames"
          color="orange lighten-4"
          :tooltip-text="$t('meetingScheduler.facilities')"
        />
      </v-card-text>
    </v-card>
    <ConfirmWithReason ref="confirmWithReason" />
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import Toolbar from './Toolbar.vue';
import EventTypeChip from '../../EventTypeChip.vue';
import DetailsDisplayElement from '../../DetailsDisplayElement.vue';
import DetailsDisplayMenu from '../../DetailsDisplayMenu.vue';

import calendarAndBookings from '../../../mixins/calendarAndBookings';
import ConfirmWithReason from '../../ConfirmWithReason.vue';
import bookingDelete from '../../../mixins/bookingDelete';

export default {
  components: {
    Toolbar,
    EventTypeChip,
    DetailsDisplayElement,
    DetailsDisplayMenu,
    ConfirmWithReason,
  },
  mixins: [calendarAndBookings, bookingDelete],
  data() {
    return {
      members: null,
    };
  },
  computed: {
    ...mapGetters([
      'facility',
      'userData',
      'isDisplayMenuOpen',
      'displayPosition',
      'displayEvent',
      'displayEventType',
      'user',
    ]),

    isOpen: {
      get() {
        return (
          this.isDisplayMenuOpen && this.displayEventType === 'App\\Meeting'
        );
      },
      set(value) {
        if (!value) {
          this.closeMenu();
        }
      },
    },
    time() {
      const start = App.helpers.getDateObject(this.displayEvent.start);
      const end = App.helpers.getDateObject(this.displayEvent.end);
      return `${this.$d(start, 'eventTime')} ~ ${this.$d(end, 'eventTime')}`;
    },
    getCreatorName() {
      const creator = this.userData(this.displayEvent.creator_id);
      if (creator === null || creator === undefined) {
        return this.$t('common.deletedUser');
      }
      return creator.name;
    },
    getFacilitiesNames() {
      return this.displayEvent.associated_facilities.map((facility) => ({
        name: this.facility(facility).name,
      }));
    },
    getUsersNames() {
      return this.members === null ? [] : this.members;
    },
    isLoggedInUserMeetingCreatorOrMember() {
      return (
        this.displayEvent.creator_id === this.user.id ||
        (this.members &&
          this.members.some((member) => member.id === this.user.id))
      );
    },
    isEditable() {
      return this.isLoggedInUserMeetingCreatorOrMember;
    },
    isDeletable() {
      return this.isLoggedInUserMeetingCreatorOrMember || this.user.is_admin;
    },
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.fetchAndSetMeetingMembers();
      }
    },
  },

  mounted () {
    window.addEventListener('keydown', this.keyDownHandler)
  },

  destroyed () {
    window.removeEventListener('keydown', this.keyDownHandler)
  },

  methods: {
    copyEvent() {
      this.$store.dispatch('setCopiedEvent', {
        subject: this.displayEvent.subject,
        description: this.displayEvent.description,
        start: this.displayEvent.start,
        end: this.displayEvent.end,
        all_day: this.displayEvent.all_day,
        facilities: this.displayEvent.associated_facilities,
        event_type_id: this.displayEvent.event_type_id,
      });
      this.closeMenu();
    },
    async fetchAndSetMeetingMembers() {
      const { data } = await axios.get(
        `/api/notifications/meeting-members-and-decision/${this.displayEvent.group_id}`,
      );
      this.members = [...data];
    },
    editEvent() {
      this.$router.push({
        name: 'schedule-meeting',
        params: { id: this.displayEvent.group_id },
      });
      this.closeMenu();
    },
    async handleDeleteEvent() {
      if (!this.isLoggedInUserMeetingCreatorOrMember && this.user.isAdmin) {
        await this.deleteBookingWithReason(
          this.$t('bookingDelete.title'),
          this.$t('bookingDelete.bookingAssociatedWithMeeting', {
            creatorName: this.getCreatorName,
          }),
        );
        return;
      }
      this.$router.push({
        name: 'meeting-scheduler',
      });
      this.closeMenu();
    },
    closeMenu() {
      this.$store.dispatch('closeDisplayMenu');
    },

    keyDownHandler(event) {
      if (event.code === 'Escape') {
        this.closeMenu();
      }
    },
  },
};
</script>
