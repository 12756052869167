const bookingDelete = {
  methods: {
    async deleteIrregularBooking(selection) {

      try {
        await axios.delete(
          '/api/facility-booking/delete/irregular-recurring/',
          {
            params: {
              irregular_id: this.displayEvent.irregular_id,
              start: this.displayEvent.start,
              delete_type: selection.value,
              delete_reason: selection.reason,
              group_id: this.displayEvent.group_id,
            },
          },
        );
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityBooking.bookingDeletedSuccessfully'),
        });
        this.$emit('delete');
        this.closeMenu();
        this.$store.dispatch('updateIsRefreshRequired', true);
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },
    async deleteBooking() {
      const confirmation = await this.$refs.confirm.open(
        this.$t('common.confirmDelete'),
        this.$t('facilityBooking.areYouSureDelete'),
        {
          color: 'red',
          width: 400,
        },
      );
      if (confirmation === false) {
        return;
      }
      try {
        await axios.delete('/api/facility-booking/delete/', {
          params: {
            group_type: this.displayEvent.group_type,
            group_id: this.displayEvent.group_id,
          },
        });

        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityBooking.bookingDeletedSuccessfully'),
        });
        this.$emit('delete');
        this.closeMenu();
        this.$store.dispatch('updateIsRefreshRequired', true);
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },

    async deleteRegularBooking(selection) {
      try {
        await axios.delete('/api/facility-booking/delete/recurring', {
          params: {
            group_type: this.displayEvent.group_type,
            group_id: this.displayEvent.group_id,
            start: this.displayEvent.start,
            delete_type: selection.value,
            delete_reason: selection.reason,
          },
        });

        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityBooking.bookingDeletedSuccessfully'),
        });
        // TODO: check these three lines
        this.$emit('delete');
        this.closeMenu();
        this.$store.dispatch('updateIsRefreshRequired', true);
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },

    async deleteBookingWithReason(title, message) {
      const userConfirmation = await this.$refs.confirmWithReason.open(
        title,
        message,
      );

      if (userConfirmation.value === true) {
        try {
          await axios.delete('/api/facility-booking/delete/', {
            params: {
              group_type: this.displayEvent.group_type,
              group_id: this.displayEvent.group_id,
              delete_reason: userConfirmation.reason,
            },
          });
          this.closeMenu();
          this.$store.dispatch('updateIsRefreshRequired', true);
        } catch {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        }
      }
    },
  },
};

export default bookingDelete;
