import { mapGetters } from 'vuex';
import { format, addSeconds, differenceInSeconds } from 'date-fns';

const bookingDragDrop = {
  computed: {
    ...mapGetters(['id', 'dropInfo', 'selectedDragDropOption']),
  },
  watch: {
    dropInfo() {
      if (this.dropInfo !== null) {
        if (
          this.dropInfo.data.group_type !== 'App\\BookingRecur' &&
          this.dropInfo.data.group_type !== 'App\\Meeting' &&
          this.dropInfo.data.group_type !== 'App\\Event'
        ) {
          this.handleDroppedEvent();
          return;
        }
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('facilityBooking.bookingCannotBeDragged'),
        });
        this.$store.dispatch('updateIsRefreshRequired', true);
      }
    },
    selectedDragDropOption() {
      if (this.selectedDragDropOption !== null) {
        this.upsertBookingBasedOnOption();
      }
    },
  },
  methods: {
    handleDroppedEvent() {
      if (this.isNewStartDateSameAsEventStartDate()) {
        if (this.isNewFacilitySameAsOldFacility()) {
          return;
        }
        this.updateBookingWithoutDateChange();
        return;
      }
      this.updateBookingWithDateChange();
    },
    isNewStartDateSameAsEventStartDate() {
      return this.dropInfo.col === this.dropInfo.data.start.slice(0, 10);
    },
    isNewFacilitySameAsOldFacility() {
      return this.dropInfo.row === this.dropInfo.data.facility_id;
    },
    updateBookingWithoutDateChange() {
      const oldEvent = { ...this.dropInfo.data };
      const newFacilityId = this.dropInfo.row;
      let facilities = oldEvent.associated_facilities;
      if (this.isNewFacilityInAssociatedFacilities(facilities, newFacilityId)) {
        this.$store.dispatch('updateIsRefreshRequired', true);
        return;
      }
      facilities = this.removeOldFacilityAndInjectNewFacility(
        facilities,
        newFacilityId,
        oldEvent.facility_id,
      );
      this.bookingUpdate({ ...oldEvent, ...{ facilities } });
    },
    updateBookingWithDateChange() {
      const event = { ...this.dropInfo.data };
      if (event.creator_id !== this.id) {
        this.handleDragAndDropBookingCreatedByOthers();
        this.$store.dispatch('updateIsRefreshRequired', true);
        return;
      }
      if (event.associated_facilities.length === 1) {
        this.$store.dispatch(
          'storeSelectedDragDropOption',
          'updateAllAssociatedBookings',
        );
        return;
      }
      this.$store.dispatch('storeDragDropOptions', {
        choice_1: {
          message: this.$t('dragDialogOptions.createNewBooking'),
          value: 'createNewBooking',
        },
        choice_2: {
          message: this.$t('dragDialogOptions.updateAllAssociatedBookings'),
          value: 'updateAllAssociatedBookings',
        },
      });
    },
    upsertBookingBasedOnOption() {
      const newStartAndEnd = this.getNewStartAndEndOfBooking();
      const event = { ...this.dropInfo.data, ...newStartAndEnd };
      if (this.selectedDragDropOption === 'createNewBooking') {
        this.createNewBooking({
          ...event,
          ...{ facilities: [this.dropInfo.row] },
        });
        return;
      }
      const facilities = this.getNewFacilitiesForAssociatedBookingUpdate();
      this.bookingUpdate({ ...event, ...{ facilities } });
    },
    getNewStartAndEndOfBooking() {
      const event = this.dropInfo.data;
      const newStartDate = this.dropInfo.col;
      const oldStart = App.helpers.getDateObject(event.start);
      const oldEnd = App.helpers.getDateObject(event.end);
      const newStart = App.helpers.getDateObject(
        `${newStartDate} ${event.start.slice(11, 16)}`,
      );
      const newEnd = addSeconds(
        newStart,
        differenceInSeconds(oldEnd, oldStart),
      );
      return {
        start: format(newStart, 'yyyy-MM-dd HH:mm:ss'),
        end: format(newEnd, 'yyyy-MM-dd HH:mm:ss'),
      };
    },
    getNewFacilitiesForAssociatedBookingUpdate() {
      const facilities = this.dropInfo.data.associated_facilities;
      const newFacilityId = this.dropInfo.row;
      const oldFacilityId = this.dropInfo.data.facility_id;
      if (this.isNewFacilityInAssociatedFacilities(facilities, newFacilityId)) {
        return facilities;
      }
      return this.removeOldFacilityAndInjectNewFacility(
        facilities,
        newFacilityId,
        oldFacilityId,
      );
    },
    async createNewBooking(event) {
      try {
        if (event.irregular_id === null) {
          await this.separateNormalBooking(event);
        } else {
          await this.separateIrregularRecurringBooking(event);
        }
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityBooking.bookingAddedSuccessfully'),
        });
      } catch (error) {
        if (error.response.data.errors.facilitiesTimeCheck !== null) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('messages.facilityHasBookingInTime'),
          });
        } else {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        }
      }
      this.$store.dispatch('updateIsRefreshRequired', true);
    },
    async separateNormalBooking(event) {
      await axios.patch('/api/facility-booking/separate-normal-booking', {
        id: event.id,
        group_type: event.group_type,
        group_id: event.group_id,
        subject: event.subject,
        description: event.description,
        start: event.start,
        end: event.end,
        all_day: event.all_day,
        event_type_id: event.event_type_id,
        facilities: event.facilities,
        user_id: this.id,
      });
    },
    async separateIrregularRecurringBooking(event) {
      const { start } = event;
      const { end } = event;
      await axios.patch(
        '/api/facility-booking/separate-recurrence-irregular-booking/',
        {
          id: event.id,
          group_type: event.group_type,
          group_ids: [event.group_id],
          subject: event.subject,
          description: event.description,
          times: [{ start, end }],
          all_day: event.all_day,
          event_type_id: event.event_type_id,
          facilities: event.facilities,
          user_id: this.id,
        },
      );
    },
    async bookingUpdate(event) {
      try {
        if (event.irregular_id === null) {
          await this.updateNormalBooking(event);
        } else {
          await this.updateIrregularRecurringBooking(event);
        }
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityBooking.bookingEditedSuccessfully'),
        });
      } catch (error) {
        if (error.response.status === 403) {
          this.handleDragAndDropBookingCreatedByOthers();
        } else if (error.response.data.errors.facilitiesTimeCheck !== null) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('messages.facilityHasBookingInTime'),
          });
        } else {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        }
      }
      this.$store.dispatch('updateIsRefreshRequired', true);
    },
    handleDragAndDropBookingCreatedByOthers() {
      this.$store.dispatch('openSnackbar', {
        color: 'error',
        message: this.$t('messages.cannotEditBookingsCreatedByOthers'),
      });
    },
    async updateNormalBooking(event) {
      await axios.patch(`/api/facility-booking/`, {
        group_type: event.group_type,
        group_id: event.group_id,
        subject: event.subject,
        description: event.description,
        start: event.start,
        end: event.end,
        all_day: event.all_day,
        event_type_id: event.event_type_id,
        facilities: event.facilities,
        user_id: this.id,
      });
    },
    async updateIrregularRecurringBooking(event) {
      await axios.patch(`/api/facility-booking/irregular-recurring/`, {
        group_type: event.group_type,
        group_ids: [event.group_id],
        subject: event.subject,
        description: event.description,
        times: [
          {
            start: event.start,
            end: event.end,
          },
        ],
        all_day: event.all_day,
        event_type_id: event.event_type_id,
        facilities: event.facilities,
        user_id: this.id,
      });
    },
    isNewFacilityInAssociatedFacilities(facilities, newFacilityId) {
      return facilities.includes(newFacilityId);
    },
    removeOldFacilityAndInjectNewFacility(facilities, newId, oldId) {
      facilities = facilities.filter((facility) => facility !== oldId);
      facilities.push(newId);
      return facilities;
    },
  },
};

export default bookingDragDrop;
