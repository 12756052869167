<template>
  <v-dialog v-model="isDialogOpen" width="350">
    <v-card>
      <v-card-title class="headline">
        {{ $t('facilityBooking.deleteRepeatingBooking') }}
      </v-card-title>
      <v-card-text>
        <template v-if="askForReason">
          {{
            $t('bookingDelete.bookingDeleteMessage', {
              creatorName: creatorName,
            })
          }}
        </template>
        <v-radio-group v-model="selection" hide-details>
          <v-radio
            :label="$t('facilityBooking.deleteThisBooking')"
            value="delete-self"
          />
          <v-radio
            :label="$t('facilityBooking.deleteFurtherBookings')"
            value="delete-further"
          />
          <v-radio
            :label="$t('facilityBooking.deleteAllBookings')"
            value="delete-all"
          />
        </v-radio-group>
      </v-card-text>
      <v-textarea
        v-if="askForReason"
        v-model="reason"
        class="ma-4"
        :label="$t('bookingDelete.reason')"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="primary" outlined @click="handleSelection">
          {{ $t('common.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    creatorName: {
      type: String,
      default: '',
    },
    askForReason: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selection: 'delete-self',
      reason: null,
    };
  },
  computed: {
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.selection = 'delete-self';
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSelection() {
      this.$emit('selection', {
        value: this.selection,
        reason: this.reason,
      });
      this.reason = null;
      this.isDialogOpen = false;
    },
    cancel() {
      this.reason = null;
      this.isDialogOpen = false;
    },
  },
};
</script>
