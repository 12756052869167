import {
  differenceInMinutes,
  differenceInCalendarDays,
  addMinutes,
  addDays,
  format,
} from 'date-fns';
import { mapGetters } from 'vuex';
import App from '../globalAppObject';
import bookingStoreAndUpdate from './bookingStoreAndUpdate';
import bookingTimeValidity from './bookingTimeValidity';

const facilityAddEdit = {
  mixins: [bookingTimeValidity, bookingStoreAndUpdate],
  data: () => ({
    error: {
      isErrorPresent: false,
      errorMessage: '',
    },
    booking: {
      id: 0,
      groupType: '',
      groupId: 0,
      irregularId: 0,
      allDay: 0,
      subject: '',
      description: '',
      start: '',
      end: '',
      eventTypeId: null,
      facilities: [],
      recurrence: null,
    },
    isLoading: false,
  }),
  computed: {
    ...mapGetters(['id', 'copiedEvent', 'bookingEditType']),
    isEditing() {
      return this.booking.id !== 0;
    },
    defaultBooking() {
      const today = App.helpers.getDateObject();
      return {
        id: 0,
        groupType: '',
        groupId: 0,
        irregularId: 0,
        allDay: 0,
        subject: '',
        description: '',
        start: format(today, 'yyyy-MM-dd HH:mm:ss'),
        end: format(today, 'yyyy-MM-dd HH:mm:ss'),
        eventTypeId: null,
        facilities: [],
        recurrence: null,
      };
    },
    isPasteAllowed() {
      return this.copiedEvent !== null && !this.isEditing;
    },
  },
  methods: {
    changeDateTime(dateTime) {
      this.booking.start = dateTime.start;
      this.booking.end = dateTime.end;
      this.$store.dispatch('storeRecurringEventStartEnd', {
        start: App.helpers.getDateObject(dateTime.start),
        end: App.helpers.getDateObject(dateTime.end),
      });
      this.fetchIsBookingValid();
    },
    async validateAndSubmit() {
      if (this.validate()) {
        this.isLoading = true;
        if (this.booking.id === 0) await this.storeBooking();
        else await this.updateBooking();
        this.isLoading = false;
      }
      this.booking.id = 0;
    },
    validate() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      return this.error.isErrorPresent === false;
    },
    getTimePairsForIrregularRecurring() {
      let dates = [...this.booking.recurrence.data.dates];
      const bookingStart = App.helpers.getDateObject(this.booking.start);
      const bookingEnd = App.helpers.getDateObject(this.booking.end);
      const minutesDuration = differenceInMinutes(bookingEnd, bookingStart);
      dates = dates.map((startDate) => {
        let start = `${startDate} ${format(bookingStart, 'HH:mm:ss')}`;
        start = App.helpers.getDateObject(start);
        const end = addMinutes(start, minutesDuration);
        return {
          start: format(start, 'yyyy-MM-dd HH:mm:ss'),
          end: format(end, 'yyyy-MM-dd HH:mm:ss'),
        };
      });
      dates.push({
        start: format(bookingStart, 'yyyy-MM-dd HH:mm:ss'),
        end: format(bookingEnd, 'yyyy-MM-dd HH:mm:ss'),
      });
      return dates;
    },
    pasteBooking() {
      this.booking.subject = this.copiedEvent.subject;
      this.booking.description = this.copiedEvent.description;
      this.booking.eventTypeId = this.copiedEvent.event_type_id;
      this.copiedEvent.facilities.forEach((facility) => {
        if (!this.booking.facilities.includes(facility)) {
          this.booking.facilities.push(facility);
        }
      });
      this.pasteNewStartEnd();
      this.fetchIsBookingValid();
    },
    pasteNewStartEnd() {
      this.booking.allDay = this.copiedEvent.all_day;
      const copiedStart = App.helpers.getDateObject(this.copiedEvent.start);
      const copiedEnd = App.helpers.getDateObject(this.copiedEvent.end);
      let newStart = App.helpers.getDateObject(this.booking.start);
      const daysDifference = differenceInCalendarDays(newStart, copiedStart);
      newStart = addDays(copiedStart, daysDifference);
      const newEnd = addDays(copiedEnd, daysDifference);
      this.booking.start = format(newStart, 'yyyy-MM-dd HH:mm:ss');
      this.booking.end = format(newEnd, 'yyyy-MM-dd HH:mm:ss');
    },
  },
};

export default facilityAddEdit;
