<template>
  <v-container class="pa-0 ma-0" fluid>
    <div class="py-1">
      <v-progress-linear
        :active="isGridLinearLoading"
        indeterminate
        absolute
        color="#0D47A1"
      />
    </div>
    <grid-header :headers="headers" />
    <week-skeleton-loader
      v-if="isGridLoading"
      :rows="items"
      :number-of-cells="headers.length"
    >
      <template #information-card="{ data }">
        <slot name="information-card" :data="data" />
      </template>
    </week-skeleton-loader>
    <span v-else>
      <grid-row
        v-for="(rowItem, index) in items"
        :key="index"
        :index="index"
        :row-item="rowItem"
      >
        <template #information-card="{ data }">
          <slot name="information-card" :data="data" />
        </template>
      </grid-row>
    </span>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import GridHeader from './GridHeader.vue';
import GridRow from './GridRow.vue';
import WeekSkeletonLoader from './WeekSkeletonLoader.vue';

export default {
  components: {
    GridHeader,
    GridRow,
    WeekSkeletonLoader,
  },
  props: {
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters(['isGridLoading', 'isGridLinearLoading']),
  },
};
</script>
