<template>
  <v-container class="pt-2 pb-0 mx-0">
    <v-col class="pa-0" cols="12" sm="3">
      <v-autocomplete
        v-if="view === 'month'"
        v-model="facilityId"
        hide-details
        item-text="name"
        item-value="id"
        :items="allFacilities"
        outlined
        dense
        :label="$t('selection.facilitySelect')"
        @change="facilitySelected()"
      >
        <template #item="data">
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.typeName }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-autocomplete
        v-else
        v-model="facilityTypeId"
        item-text="name"
        item-value="id"
        :items="allFacilityTypes"
        outlined
        dense
        hide-details
        :menu-props="{ closeOnContentClick: true }"
        :label="$t('selection.facilityTypeSelect')"
        :placeholder="$t('common.all')"
        @change="facilityTypeSelected()"
      >
        <template #prepend-item>
          <v-list-item ripple @click="selectAllFacilities">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('common.all') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2" />
        </template>
      </v-autocomplete>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    view: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    facilityMenu: [],
    facilityTypesMenu: [],
    tab: 0,

    facilityId: 0,
    facilityTypeId: 0,
  }),

  computed: {
    ...mapGetters(['id', 'allFacilities', 'allFacilityTypes']),
  },

  watch: {
    tab() {
      this.$emit('tab-change', this.tab);
    },
  },

  created() {
    this.getDefaultData();
  },

  methods: {
    getDefaultData() {
      this.tab = 0;
    },

    facilitySelected() {
      this.$emit('facility-selected', this.facilityId);
    },

    facilityTypeSelected() {
      this.$emit('facility-type-selected', this.facilityTypeId);
    },

    selectAllFacilities() {
      this.facilityTypeId = 0;
      this.facilityTypeSelected();
    },
  },
};
</script>
