<template>
  <v-card-title class="pa-0 py-1 pr-1">
    <v-spacer />
    <tooltip-button
      icon="mdi-content-copy"
      :message="$t('viewScheduleCard.copy')"
      @click="$emit('copy')"
    />
    <tooltip-button
      v-if="isEditable"
      icon="mdi-pencil-outline"
      :message="$t('viewScheduleCard.edit')"
      @click="$emit('edit')"
    />
    <tooltip-button
      v-if="isDeletable"
      icon="mdi-delete-outline"
      :message="$t('viewScheduleCard.delete')"
      @click="$emit('delete')"
    />
    <tooltip-button
      icon="mdi-close"
      :message="$t('common.close')"
      @click="$emit('close')"
    />
  </v-card-title>
</template>

<script>
import TooltipButton from './TooltipButton.vue';

export default {
  components: {
    TooltipButton,
  },
  props: {
    isCopyable: {
      type: Boolean,
      default: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isDeletable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
