<template>
  <v-menu
    v-model="isOpen"
    :position-x="menuPosition.x"
    :position-y="menuPosition.y"
    :close-on-content-click="false"
  >
    <v-card width="400px">
      <v-card-title class="pa-0 py-2 px-2">
        <span>{{
          date === null
            ? ''
            : $i18n.d(
                App.helpers.getDateObject(date),
                'weekViewHeaderWithMonth',
              )
        }}</span>
        <v-spacer />
        <v-btn icon @click="isOpen = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <event-chip
          v-for="(event, index) in getFilteredEvents"
          :key="index"
          :event="event"
          :start="App.helpers.getDateObject(date)"
          :end="App.helpers.getDateObject(date)"
          :height="30"
          class="py-1"
          @closeMenu="isOpen = false"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import EventChip from './EventChip.vue';

export default {
  components: { EventChip },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isOpen: false,
      date: null,
      menuPosition: { x: null, y: null },
    };
  },
  computed: {
    ...mapGetters(['moreMenuInfo']),
    getFilteredEvents() {
      if (this.moreMenuInfo === null) {
        return [];
      }
      const start = `${this.date} 00:00:00`;
      const end = `${this.date} 23:59:59`;
      return this.items.filter(
        (item) => item.start <= end && item.end >= start,
      );
    },
  },
  watch: {
    moreMenuInfo() {
      if (this.moreMenuInfo !== null) {
        setTimeout(() => {
          this.setDateForMoreMenu();
          this.openMenuAndSetPosition();
        }, 1);
      }
    },
    isOpen() {
      if (!this.isOpen) {
        this.closeMenu();
      }
    },
  },
  methods: {
    setDateForMoreMenu() {
      this.date = this.moreMenuInfo.date;
    },
    openMenuAndSetPosition() {
      this.isOpen = true;
      this.menuPosition.x = this.moreMenuInfo.x;
      this.menuPosition.y = this.moreMenuInfo.y;
    },
    closeMenu() {
      this.$store.dispatch('resetGrid');
    },
  },
};
</script>
