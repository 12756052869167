<template>
  <v-sheet height="800">
    <div class="py-1">
      <v-progress-linear
        :active="isGridLinearLoading"
        indeterminate
        absolute
        color="#0D47A1"
      />
    </div>
    <v-calendar
      ref="calendar"
      :start="currentDate"
      :events="events"
      color="primary"
      :event-color="getEventColor"
      :event-text-color="getEventTextColor"
      :weekdays="daysArrangement"
      :locale="$i18n.locale"
      @mousedown:event="startEventDrag"
      @click:event="storeClickedEventChipInfo"
      @click:day="storeClickInfo($event.date)"
      @click:date="storeClickInfo($event.date)"
      @mousedown:day="storeDragSelectStartInfo"
      @mouseenter:day="handleMouseEnterDay"
      @mouseup:day="handleMouseUpOnDay"
      @click:more="storeMoreMenuInfo"
    >
      <template #event="data">
        <strong v-if="$vuetify.breakpoint.smAndUp">
          {{ getItemTime(data.event) }}
        </strong>
        {{ data.event.subject }}
      </template>
      <template #day-label="{ date, day }">
        <v-btn
          class="mt-n1"
          :style="{ width: '99%' }"
          tile
          elevation="0"
          :color="getDateHeaderColor(date)"
        >
          {{ day }} {{ getHolidayNameForDate(date) }}
        </v-btn>
      </template>
    </v-calendar>
    <more-menu :items="items" />
  </v-sheet>
</template>

<script>
import {
  addDays,
  differenceInCalendarDays,
  format,
  isSaturday,
  isSunday,
  isToday,
} from 'date-fns';
import { mapGetters } from 'vuex';
import MoreMenu from './MoreMenu.vue';

export default {
  components: { MoreMenu },
  props: {
    row: {
      type: Number,
      default: null,
    },
    currentDate: {
      type: Date,
      default() {
        return App.helpers.getDateObject();
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      events: [],
    };
  },
  computed: {
    ...mapGetters([
      'nationalHolidays',
      'idOfEventBeingDragged',
      'normalizedEventTypes',
      'isDragSelect',
      'dragSelectInfo',
      'isGridLinearLoading',
      'firstDayOfTheWeek',
      'preferences',
    ]),
    daysArrangement() {
      if (this.firstDayOfTheWeek === 'sunday') {
        return [0, 1, 2, 3, 4, 5, 6];
      }
      return [1, 2, 3, 4, 5, 6, 0];
    },
  },
  watch: {
    items() {
      this.events = [...this.items];
    },
  },
  methods: {
    storeClickedEventChipInfo({ event, nativeEvent }) {
      nativeEvent.stopPropagation();
      this.$store.dispatch('storeClickedEventChipInfo', {
        data: event,
        x: nativeEvent.x,
        y: nativeEvent.y,
      });
    },
    getEventColor(event) {
      if (event.event_type_id === null) {
        return '#CFD8DC';
      }
      return this.normalizedEventTypes[event.event_type_id].color;
    },
    getEventTextColor(event) {
      return this.$root.getTextColor(this.getEventColor(event));
    },
    getItemTime(event) {
      if (event.all_day === 1) {
        return '';
      }
      const start = event.start.slice(11, 16);
      if (this.preferences.is_end_time_shown) {
        const end = event.end.slice(11, 16);
        return `${start}~${end}`;
      }
      return start;
    },
    storeClickInfo(date) {
      if (this.row === null || this.row === 0) {
        return;
      }
      this.$store.dispatch('storeClickInfo', {
        row: this.row,
        col: date,
        x: window.event.x,
        y: window.event.y,
      });
    },
    storeMoreMenuInfo(data) {
      window.event.stopPropagation();
      this.$store.dispatch('storeMoreMenuInfo', {
        date: data.date,
        x: window.event.x,
        y: window.event.y,
      });
    },
    storeDragSelectStartInfo(value) {
      if (this.row === 0 || this.row === null) {
        return;
      }
      this.$store.dispatch('startDragSelect', {
        row: this.row,
        start: value.date,
      });
    },
    startEventDrag({ event, nativeEvent }) {
      this.$store.dispatch('startEventDrag', event.id);
      nativeEvent.stopPropagation();
    },
    handleMouseEnterDay(value) {
      if (this.idOfEventBeingDragged !== null) {
        this.moveDraggedEventToNewDate(value.date);
        return;
      }
      if (this.isDragSelect) {
        this.$store.dispatch('updateDragSelect', value.date);
      }
    },
    moveDraggedEventToNewDate(newStartDate) {
      this.events = this.items.map((event) => {
        if (event.id !== this.idOfEventBeingDragged.id) {
          return event;
        }
        const newStartEnd = this.getNewStartEndForEvent(newStartDate, event);
        return { ...event, ...newStartEnd };
      });
    },
    getNewStartEndForEvent(newStartDate, event) {
      newStartDate = App.helpers.getDateObject(newStartDate);
      const oldStart = App.helpers.getDateObject(event.start);
      const oldEnd = App.helpers.getDateObject(event.end);
      const differenceInDays = differenceInCalendarDays(newStartDate, oldStart);
      if (differenceInDays === 0) {
        return { start: event.start, end: event.end };
      }
      let newStart = addDays(oldStart, differenceInDays);
      let newEnd = addDays(oldEnd, differenceInDays);
      newStart = format(newStart, 'yyyy-MM-dd HH:mm:ss');
      newEnd = format(newEnd, 'yyyy-MM-dd HH:mm:ss');
      return { start: newStart, end: newEnd };
    },
    handleMouseUpOnDay(value) {
      if (this.idOfEventBeingDragged !== null) {
        this.$store.dispatch('storeDropInfo', {
          data: this.items.find(
            (event) => event.id === this.idOfEventBeingDragged.id,
          ),
          row: this.row,
          col: value.date,
        });
        this.$store.dispatch('endEventDrag');
        return;
      }
      if (this.isDragSelect) {
        this.$store.dispatch('endDragSelect');
        const { start } = this.dragSelectInfo;
        const { end } = this.dragSelectInfo;
        if (start === end) {
          return;
        }
        this.storeSelectInfo(window.event.x, window.event.y, start, end);
      }
    },
    storeSelectInfo(x, y, start, end) {
      [start, end] = start > end ? [end, start] : [start, end];
      this.$store.dispatch('storeSelectInfo', {
        x,
        y,
        row: this.row,
        col: { start, end },
      });
    },
    getDateHeaderColor(date) {
      if (this.isCellDragSelected(date)) {
        return 'blue-grey lighten-3';
      }
      if (this.isDateToday(date)) {
        return 'primary';
      }
      if (
        this.getHolidayNameForDate(date) !== '' ||
        isSunday(App.helpers.getDateObject(date))
      ) {
        return '#EF9A9A';
      }
      if (isSaturday(App.helpers.getDateObject(date))) {
        return '#BBDEFB';
      }
      return 'white';
    },
    isCellDragSelected(date) {
      if (this.dragSelectInfo === null) {
        return false;
      }
      const { start } = this.dragSelectInfo;
      const { end } = this.dragSelectInfo;
      return (date >= end && date <= start) || (date <= end && date >= start);
    },
    isDateToday(date) {
      return isToday(App.helpers.getDateObject(date));
    },
    getHolidayNameForDate(date) {
      const holiday = this.nationalHolidays.find(
        (hol) => hol.date === App.helpers.getISODateString(date),
      );
      return holiday !== undefined ? holiday.name : '';
    },
  },
};
</script>
