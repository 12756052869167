var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"bottom":"","open-delay":500,"color":"#000000","disabled":_vm.$vuetify.breakpoint.xsOnly,"open-on-hover":_vm.$vuetify.breakpoint.smAndUp},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({class:{ shake: _vm.isShake, 'd-flex': true, noselect: true },style:({ cursor: 'pointer' }),on:{"mousedown":function($event){return $event.stopPropagation()},"click":function($event){return _vm.displayEvent()}}},on),[(_vm.isEventStartBeforeDate)?_c('div',{style:({
          width: '0px',
          height: '0px',
          'border-top': _vm.borderTopAndBottomStyle,
          'border-right': `12px solid ${_vm.chipColor}`,
          'border-bottom': _vm.borderTopAndBottomStyle,
        })}):_vm._e(),_c('div',{staticClass:"caption",style:({
          'background-color': _vm.chipColor,
          width: '100%',
          height: `${_vm.height}px`,
          'border-radius': _vm.borderRadius,
        })},[_c('div',{staticClass:"pl-1",style:({
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: _vm.chipTextColor,
          })},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v(" "+_vm._s(_vm.eventTime)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.event.subject)+" ")])]),(_vm.isEventEndAfterDate)?_c('div',{style:({
          width: '0px',
          height: '0px',
          'border-top': _vm.borderTopAndBottomStyle,
          'border-left': `12px solid ${_vm.chipColor}`,
          'border-bottom': _vm.borderTopAndBottomStyle,
          'background-color': 'transparent',
        })}):_vm._e()])]}}])},[_vm._v(" "+_vm._s(_vm.getTimeDisplay)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.getEventDetailsDisplay)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }