import { format } from 'date-fns';
import App from '../globalAppObject';

const bookingTimeValidity = {
  data: () => ({
    overlappingFacilityStartEnd: null,
  }),
  computed: {
    dateTimeError() {
      if (this.overlappingFacilityStartEnd === null) {
        return { isError: false, message: '' };
      }
      return {
        isError: true,
        message: this.$t('messages.facilityHasBookingInTime'),
      };
    },
    facilitiesBusy() {
      if (this.overlappingFacilityStartEnd === null) {
        return [];
      }
      return this.overlappingFacilityStartEnd.map((item) => item.facility_id);
    },
    recurrenceError() {
      if (
        this.booking.recurrence === null ||
        this.overlappingFacilityStartEnd === null
      ) {
        return { isError: false, datesNotAllowed: [] };
      }
      let dates = this.overlappingFacilityStartEnd.map((item) => {
        const date = App.helpers.getDateObject(item.start);
        return format(date, 'yyyy-MM-dd');
      });
      dates = dates.filter(
        (date, key, self) =>
          self.indexOf(date) === key &&
          this.booking.start.slice(0, 10) !== date,
      );
      return { isError: true, datesNotAllowed: [...dates] };
    },
  },
  methods: {
    async fetchIsBookingValid() {
      let { recurrence } = this.booking;
      if (this.booking.facilities.length === 0) {
        return;
      }
      if (recurrence !== null && recurrence.data === null) {
        recurrence = null;
      }
      const parameters = this.getValidityParameters(recurrence);
      const { data } = await axios.get('/api/facility-booking/get-overlap/', {
        params: { ...parameters },
      });
      this.overlappingFacilityStartEnd = typeof data === 'object' ? data : null;
    },
    getValidityParameters(recurrence) {
      const start = App.helpers.getDateObject(this.booking.start);
      const end = App.helpers.getDateObject(this.booking.end);
      let parameters = {
        facilities: [...this.booking.facilities],
        all_day: this.booking.allDay,
        start: format(start, 'yyyy-MM-dd HH:mm:ss'),
        end: format(end, 'yyyy-MM-dd HH:mm:ss'),
      };
      if (recurrence === null) {
        parameters = {
          ...parameters,
          ...this.getNoRecurrenceParameters(),
        };
      } else if (recurrence.type === 'irregular_recurrence') {
        parameters = {
          ...parameters,
          ...this.getIrregularRecurrenceParameters(),
        };
      } else if (recurrence.type === 'regular_recurrence') {
        parameters = {
          ...parameters,
          ...this.getRegularRecurrenceParameters(),
        };
      }
      return parameters;
    },
    getNoRecurrenceParameters() {
      return {
        group_type: 'App\\Booking',
        group_id: this.booking.groupId,
        recurrence_type: 'none',
      };
    },
    getIrregularRecurrenceParameters() {
      const { data } = this.booking.recurrence;
      const groupIds = [...data.groupIds];
      const times = this.getTimePairsForIrregularRecurring();
      groupIds.push(this.booking.groupId);
      return {
        group_type: 'App\\Booking',
        group_ids: [...groupIds],
        recurrence_type: 'irregular_recurrence',
        times,
      };
    },
    getRegularRecurrenceParameters() {
      const data = { ...this.booking.recurrence.data };
      return {
        ...{
          group_type: 'App\\BookingRecur',
          group_id: this.booking.groupId,
          recurrence_type: 'regular_recurrence',
        },
        ...data,
      };
    },
  },
};

export default bookingTimeValidity;
