<template>
  <v-dialog v-model="isDialogOpen" width="350">
    <v-card>
      <v-card-title class="headline">
        {{ $t('facilityBooking.editRepeatingBooking') }}
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="selection" hide-details>
          <v-radio
            :label="$t('facilityBooking.editThisBooking')"
            value="edit-self"
          />
          <v-radio
            :label="$t('facilityBooking.editFurtherBookings')"
            value="edit-further"
          />
          <v-radio
            :label="$t('facilityBooking.editAllBookings')"
            value="edit-all"
          />
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="isDialogOpen = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          outlined
          @click="$emit(selection), (isDialogOpen = false)"
        >
          {{ $t('common.edit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selection: 'edit-self',
    };
  },
  computed: {
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.selection = 'edit-self';
        this.$emit('input', value);
      },
    },
  },
};
</script>
