<template>
  <v-menu
    v-model="isOpen"
    absolute
    :position-x="displayPosition.x"
    :position-y="displayPosition.y"
    :close-on-content-click="false"
  >
    <v-card v-if="isOpen" width="500px">
      <toolbar
        :is-editable="isEditable"
        :is-deletable="isDeletable"
        @copy="copyEvent"
        @edit="editEvent"
        @delete="deleteAppropriateEntity"
        @close="closeMenu"
      />
      <v-card-text>
        <div class="text-h6 font-weight-bold px-2" style="text-align: justify">
          {{ displayEvent.subject }}
        </div>
        <div class="d-flex justify-space-between align-center pl-2">
          <span class="grey--text text--darken-3">
            {{ time }}
          </span>
          <event-type-chip
            v-if="displayEvent.event_type_id != null"
            :id="displayEvent.event_type_id"
          />
        </div>
        <details-display-element
          v-if="eventPlace !== null"
          :icon="'mdi-map-marker'"
          :message="eventPlace"
          :tooltip-text="$t('event.location')"
          :is-html-view="true"
        />
        <details-display-element
          v-if="displayEvent.description"
          :icon="'mdi-text'"
          :message="formatDescription(displayEvent.description)"
          :tooltip-text="$t('meetingScheduler.body')"
          :is-html-view="true"
        />
        <details-display-element
          :icon="'mdi-account-edit'"
          :message="getCreatorName"
          :tooltip-text="$t('common.createdBy')"
        />
        <details-display-menu
          icon="mdi-seat"
          :details="getFacilitiesNames"
          color="orange lighten-4"
          :tooltip-text="$t('meetingScheduler.facilities')"
        />
      </v-card-text>
    </v-card>
    <confirm ref="confirm" />
    <ConfirmWithReason ref="confirmWithReason" />
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { isSameDay } from 'date-fns';
import Confirm from '../../Confirm.vue';
import ConfirmWithReason from '../../ConfirmWithReason.vue';
import Toolbar from './Toolbar.vue';
import EventTypeChip from '../../EventTypeChip.vue';
import DetailsDisplayElement from '../../DetailsDisplayElement.vue';
import DetailsDisplayMenu from '../../DetailsDisplayMenu.vue';

import calendarAndBookings from '../../../mixins/calendarAndBookings';
import bookingDelete from '../../../mixins/bookingDelete';

export default {
  components: {
    Confirm,
    ConfirmWithReason,
    Toolbar,
    EventTypeChip,
    DetailsDisplayElement,
    DetailsDisplayMenu,
  },
  mixins: [calendarAndBookings, bookingDelete],
  data() {
    return {
      details: null,
      hasCalendarEditPermission: false,
    };
  },
  computed: {
    ...mapGetters([
      'facility',
      'userData',
      'isDisplayMenuOpen',
      'displayPosition',
      'displayEvent',
      'displayEventType',
      'user',
    ]),

    isOpen: {
      get() {
        return this.isDisplayMenuOpen && this.displayEventType === 'App\\Event';
      },
      set(value) {
        if (!value) {
          this.closeMenu();
        }
      },
    },
    time() {
      const start = App.helpers.getDateObject(this.displayEvent.start);
      const end = App.helpers.getDateObject(this.displayEvent.end);
      if (!this.displayEvent.all_day) {
        return `${this.$d(start, 'eventTime')} ~ ${this.$d(end, 'eventTime')}`;
      }
      if (isSameDay(start, end)) {
        return `${this.$d(start, 'allDayEvent')}`;
      }
      return `${this.$d(start, 'allDayEvent')} ~
        ${this.$d(end, 'allDayEvent')}`;
    },
    getCreatorName() {
      const creator = this.userData(this.displayEvent.creator_id);
      if (creator === null || creator === undefined) {
        return this.$t('common.deletedUser');
      }
      return creator.name;
    },
    getFacilitiesNames() {
      return this.displayEvent.associated_facilities.map((facility) => ({
        name: this.facility(facility).name,
      }));
    },
    eventPlace() {
      return this.details === null || this.details.place === null
        ? null
        : this.formatPlace(this.details.place);
    },
    isEditable() {
      if (this.details === null || this.details.calendar_id === null) {
        return false;
      }
      if (this.hasCalendarEditPermission) {
        return true;
      }
      return false;
    },
    isDeletable() {
      if (this.details === null || this.details.calendar_id === null) {
        return false;
      }
      if (this.hasCalendarEditPermission || this.user.isAdmin) {
        return true;
      }

      return false;
    },
  },

  watch: {
    isOpen(value) {
      if (value) {
        this.fetchEventDetails().then(() => {
          this.updateCalendarEditPermissionStatus();
        });
      }
    },
  },

  mounted () {
    window.addEventListener('keydown', this.keyDownHandler)
  },

  destroyed () {
    window.removeEventListener('keydown', this.keyDownHandler)
  },

  methods: {
    async updateCalendarEditPermissionStatus() {
      this.hasCalendarEditPermission = await axios
        .get(`api/user/check-permission/calendar/${this.details.calendar_id}/`)
        .then(() => true)
        .catch(() => false);
    },
    async fetchEventDetails() {
      const { data } = await axios.get(
        `/api/events/${this.displayEvent.group_id}`,
      );
      this.details = { ...data.event };
    },
    copyEvent() {
      this.$store.dispatch('setCopiedEvent', {
        subject: this.displayEvent.subject,
        description: this.displayEvent.description,
        start: this.displayEvent.start,
        end: this.displayEvent.end,
        all_day: this.displayEvent.all_day,
        facilities: this.displayEvent.associated_facilities,
        event_type_id: this.displayEvent.event_type_id,
      });
      this.closeMenu();
    },
    async editEvent() {
      this.$router.push({ name: 'week' });
      this.$store.dispatch('updateEvent', {
        id: this.details.id,
        calendar_id: this.details.calendar_id,
        event_type_id: this.details.event_type_id,
        subject: this.details.subject,
        description: this.details.description,
        all_day: this.details.all_day ? 1 : 0,
        priority: this.details.priority ? 1 : 0,
        place: this.details.place,
        busy: this.details.busy ? 1 : 0,
        updated_by: this.details.updated_by,
        updated_at: this.details.updated_at,
        start: this.details.start,
        end: this.details.end,
        user_id: this.user.id,
      });
      const response = await axios.get(`api/users/${this.user.id}/calendars`);
      this.$store.dispatch('updateFormExtras', {
        defaultCalendar: this.details.calendar_id,
        calendars: response.data.calendars,
        formDates: {
          start: this.details.start,
          end: this.details.end,
        },
        isEditing: true,
        timeRange: [],
        hasTime: false,
      });
      this.$store.dispatch(
        'updateEventFacilities',
        this.displayEvent.associated_facilities,
      );
      this.closeMenu();
      this.$store.dispatch('openAddEditEventSheet');
    },

    async deleteAppropriateEntity() {
      if (!this.hasCalendarEditPermission && this.user.isAdmin) {
        await this.deleteBookingWithReason(
          this.$t('bookingDelete.title'),
          this.$t('bookingDelete.bookingAssociatedWithEvent', {
            creatorName: this.getCreatorName,
          }),
        );
        return;
      }

      await this.deleteEvent();
    },

    async deleteEvent() {
      const confirmation = await this.$refs.confirm.open(
        this.$t('event.delete'),
        this.$t('event.deleteBody'),
      );
      if (confirmation === true) {
        await axios.delete(`api/events/${this.displayEvent.group_id}`);
        this.closeMenu();
        this.$store.dispatch('updateIsRefreshRequired', true);
      }
    },
    closeMenu() {
      this.$store.dispatch('closeDisplayMenu');
    },

    keyDownHandler(event) {
      if (event.code === 'Escape') {
        this.closeMenu();
      }
    },
  },
};
</script>
