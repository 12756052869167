<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text v-show="!!message" class="mt-4 text--primary">
        {{ message }}
      </v-card-text>
      <v-textarea
        v-model="reason"
        class="ma-4"
        :label="$t('bookingDelete.reason')"
      ></v-textarea>
      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn text @click="cancel">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn outlined :color="options.color" @click.native="agree">
          {{ $t('common.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 350,
    },
    reason: null,
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve({
        value: true,
        reason: this.reason,
      });
      this.reason = null;
      this.dialog = false;
    },
    cancel() {
      this.reason = null;
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
