<template>
  <div>
    <v-row
      v-for="(row, rowIndex) in rowDetails"
      :key="rowIndex"
      no-gutters
      class="mt-0"
      :style="{ height: `${rowHeight}px` }"
    >
      <v-col v-for="cell in numberOfCells" :key="cell">
        <v-card
          class="ma-0"
          :ripple="false"
          tile
          outlined
          elevation="0"
          :style="{
            'border-width': '0 0 1px 1px',
          }"
          height="99%"
          width="100%"
        >
          <div v-if="cell === 1" :style="{ height: '100%' }">
            <slot
              v-if="isInformationGiven(row)"
              name="information-card"
              :data="row"
            >
              {{ row.name }}
            </slot>
            <v-skeleton-loader v-else type="card" height="100" tile />
          </div>
          <v-skeleton-loader
            v-else
            :type="`text@${getRandomNumberOfEvents()}`"
            class="pr-2"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DEFAULT_ROW_HEIGHT } from './gridConstants';

export default {
  props: {
    rows: {
      type: Array,
      default() {
        return [];
      },
    },
    numberOfRows: {
      type: Number,
      default: 10,
    },
    numberOfCells: {
      type: Number,
      default: 7,
    },
  },
  computed: {
    rowDetails() {
      let rowDetails = [...this.rows];
      if (this.rows.length === 0) {
        for (let i = 0; i < this.numberOfRows; i += 1) {
          rowDetails.push({});
        }
        return rowDetails;
      }
      rowDetails = rowDetails.map((row) => row.info);
      return rowDetails;
    },
    rowHeight() {
      return DEFAULT_ROW_HEIGHT;
    },
  },
  methods: {
    getRandomNumberOfEvents() {
      return Math.round(Math.random() * 3);
    },
    isInformationGiven(row) {
      if (row.name) {
        return true;
      }
      return false;
    },
  },
};
</script>
