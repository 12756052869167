<template>
  <v-row
    no-gutters
    :style="{
      position: 'sticky',
      top: `${$vuetify.application.top}px`,
      'z-index': 3,
    }"
  >
    <v-col
      v-for="(header, index) in headers"
      :key="index"
      :style="{ width: getHeaderWidth }"
    >
      <v-card
        v-if="isNotValidDate(header)"
        class="
          d-flex d-inline-block
          justify-space-between
          flex-column
          pl-1
          pt-1
        "
        color="#F1F8E9"
        :style="{
          height: '45px',
          fontSize: namesHeaderFontSize,
          border: 'thin solid rgba(0, 0, 0, 0.12)',
          'border-width': '1px 0 1px 1px',
        }"
        outlined
        tile
      >
        {{ header }}
      </v-card>
      <v-card
        v-else
        class="d-flex d-inline-block justify-space-between flex-column"
        :color="getHeaderBackgroundColor(header)"
        :style="{
          height: '45px',
          color: getHeaderTextColor(header),
          border: 'thin solid rgba(0, 0, 0, 0.12)',
          'border-width': '1px 0 1px 1px',
        }"
        outlined
        tile
        align="center"
      >
        <span :style="{ fontSize: dayAndHolidayFontSize }">
          {{ getDayOfWeekAndHolidayName(header) }}
        </span>
        <span :style="{ fontSize: dateAndMonthFontSize }">
          {{ getHeaderDateAndMonth(header, index) }}
        </span>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { isToday, isSaturday, isSunday, isValid } from 'date-fns';

import { mapGetters } from 'vuex';
import fgColourMixin from '../../mixins/foregroundColour';

export default {
  mixins: [fgColourMixin],
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['nationalHolidays']),
    namesHeaderFontSize() {
      return this.$vuetify.breakpoint.name === 'xs' ? '9px' : '16px';
    },
    dateAndMonthFontSize() {
      return this.$vuetify.breakpoint.name === 'xs' ? '12px' : '19px';
    },
    dayAndHolidayFontSize() {
      return this.$vuetify.breakpoint.name === 'xs' ? '8px' : '12px';
    },
    getHeaderWidth() {
      return `${100 / this.headers.length}%`;
    },
  },
  methods: {
    getHeaderTextColor(header) {
      const color = this.getHeaderBackgroundColor(header);
      return this.getTextColor(color === '' ? '#FFFFFF' : color);
    },
    getHeaderBackgroundColor(header) {
      const date = App.helpers.getDateObject(header);
      if (!isValid(date)) {
        return '';
      }
      return this.getDateBackgroundColor(date);
    },
    getDateBackgroundColor(date) {
      if (isToday(date)) {
        return 'primary';
      }
      if (isSunday(date) || this.isHoliday(date)) {
        return '#EF9A9A';
      }
      if (isSaturday(date)) {
        return '#BBDEFB';
      }
      return '';
    },
    isHoliday(date) {
      return this.getHolidayNameForDate(date) !== '';
    },
    isNotValidDate(header) {
      const date = App.helpers.getDateObject(header);
      return !isValid(date);
    },
    getDayOfWeekAndHolidayName(header) {
      const date = App.helpers.getDateObject(header);
      const day = this.$i18n.d(date, 'weekViewHeaderDay');
      const holidayName = this.getHolidayNameForDate(date);
      return holidayName === '' ? day : `${day} ${holidayName}`;
    },
    getHolidayNameForDate(date) {
      const holiday = this.nationalHolidays.find(
        (hol) => hol.date === App.helpers.getISODateString(date),
      );
      return holiday !== undefined ? holiday.name : '';
    },
    getHeaderDateAndMonth(header, index) {
      const date = App.helpers.getDateObject(header);
      const dateAndMonth = this.$i18n.d(date, 'weekViewHeaderWithMonth');
      const dayOfTheMonth = date.getDate();
      if (dayOfTheMonth === 1 || index === 1) {
        return dateAndMonth;
      }
      return dayOfTheMonth;
    },
  },
};
</script>
