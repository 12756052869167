<template>
  <v-menu
    v-model="isOpen"
    absolute
    :position-x="displayPosition.x"
    :position-y="displayPosition.y"
    :close-on-content-click="false"
  >
    <v-card v-if="isOpen" width="500px">
      <toolbar
        :is-editable="isEditable"
        :is-deletable="isDeletable"
        @copy="copyEvent"
        @edit="checkIfEditOptionsToBeProvided"
        @delete="checkIfDeleteOptionsToBeProvided"
        @close="closeMenu"
      />
      <edit-options-dialog
        v-model="isEditOptionsDialogOpen"
        @edit-self="editIrregularBooking('self')"
        @edit-further="editIrregularBooking('further')"
        @edit-all="editIrregularBooking('all')"
      />
      <DeleteOptionsDialogWithConditionalReason
        v-model="isDeleteOptionsDialogOpen"
        :creator-name="getCreatorName"
        :ask-for-reason="askForReason"
        @selection="deleteGroupedBooking"
      />
      <v-card-text class="pt-0">
        <div class="text-h6 font-weight-bold px-2" style="text-align: justify">
          {{ displayEvent.subject }}
        </div>
        <div class="d-flex justify-space-between align-center pl-2">
          <span class="grey--text text--darken-3">
            {{ time }}
          </span>
          <event-type-chip
            v-if="displayEvent.event_type_id != null"
            :id="displayEvent.event_type_id"
          />
        </div>
        <details-display-element
          v-if="displayEvent.description"
          :icon="'mdi-text'"
          :message="formatDescription(displayEvent.description)"
          :tooltip-text="$t('meetingScheduler.body')"
          :is-html-view="true"
        />
        <details-display-element
          :icon="'mdi-account-edit'"
          :message="getCreatorName"
          :tooltip-text="$t('common.createdBy')"
        />
        <details-display-menu
          icon="mdi-seat"
          :details="getFacilitiesNames"
          color="orange lighten-4"
          :tooltip-text="$t('meetingScheduler.facilities')"
        />
      </v-card-text>
    </v-card>
    <confirm ref="confirm" />
    <ConfirmWithReason ref="confirmWithReason" />
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { isSameDay } from 'date-fns';
import Toolbar from './Toolbar.vue';
import EditOptionsDialog from './EditOptionsDialog.vue';
import DeleteOptionsDialogWithConditionalReason from './DeleteOptionsDialogWithConditionalReason.vue';
import Confirm from '../../Confirm.vue';
import EventTypeChip from '../../EventTypeChip.vue';
import DetailsDisplayElement from '../../DetailsDisplayElement.vue';
import DetailsDisplayMenu from '../../DetailsDisplayMenu.vue';

import calendarAndBookings from '../../../mixins/calendarAndBookings';
import bookingEdit from '../../../mixins/bookingEdit';
import bookingDelete from '../../../mixins/bookingDelete';
import ConfirmWithReason from '../../ConfirmWithReason.vue';

export default {
  components: {
    Toolbar,
    EditOptionsDialog,
    DeleteOptionsDialogWithConditionalReason,
    Confirm,
    EventTypeChip,
    DetailsDisplayElement,
    DetailsDisplayMenu,
    ConfirmWithReason,
  },
  mixins: [calendarAndBookings, bookingEdit, bookingDelete],
  data() {
    return {
      isEditOptionsDialogOpen: false,
      isDeleteOptionsDialogOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'userData',
      'facility',
      'isDisplayMenuOpen',
      'displayPosition',
      'displayEvent',
      'displayEventType',
      'user',
    ]),
    isOpen: {
      get() {
        return (
          this.isDisplayMenuOpen &&
          (this.displayEventType === 'App\\Booking' ||
            this.displayEventType === 'App\\BookingRecur')
        );
      },
      set(value) {
        if (!value) {
          this.closeMenu();
        }
      },
    },
    time() {
      const start = App.helpers.getDateObject(this.displayEvent.start);
      const end = App.helpers.getDateObject(this.displayEvent.end);
      if (!this.displayEvent.all_day) {
        return `${this.$d(start, 'eventTime')} ~ ${this.$d(end, 'eventTime')}`;
      }
      if (isSameDay(start, end)) {
        return `${this.$d(start, 'allDayEvent')}`;
      }
      return `${this.$d(start, 'allDayEvent')} ~
        ${this.$d(end, 'allDayEvent')}`;
    },
    getCreatorName() {
      const creator = this.userData(this.displayEvent.creator_id);
      if (creator === null || creator === undefined) {
        return this.$t('common.deletedUser');
      }
      return creator.name;
    },
    getFacilitiesNames() {
      return this.displayEvent.associated_facilities.map((facility) => ({
        name: this.facility(facility).name,
      }));
    },
    isBookingIrregular() {
      return (
        this.displayEvent.irregular_id &&
        this.displayEvent.irregular_id !== null
      );
    },
    isBookingRegular() {
      return this.displayEventType === 'App\\BookingRecur';
    },
    isLoggedInUserBookingCreator() {
      return this.displayEvent.creator_id === this.user.id;
    },
    isEditable() {
      return this.isLoggedInUserBookingCreator;
    },
    isDeletable() {
      return this.isLoggedInUserBookingCreator || !!this.user.isAdmin;
    },
    askForReason() {
      return this.user.isAdmin && !this.isLoggedInUserBookingCreator;
    },
  },

  mounted () {
    window.addEventListener('keydown', this.keyDownHandler)
  },

  destroyed () {
    window.removeEventListener('keydown', this.keyDownHandler)
  },

  methods: {
    copyEvent() {
      this.$store.dispatch('setCopiedEvent', {
        subject: this.displayEvent.subject,
        description: this.displayEvent.description,
        start: this.displayEvent.start,
        end: this.displayEvent.end,
        all_day: this.displayEvent.all_day,
        facilities: this.displayEvent.associated_facilities,
        event_type_id: this.displayEvent.event_type_id,
      });
      this.closeMenu();
    },
    async checkIfEditOptionsToBeProvided() {
      if (this.isBookingIrregular) {
        this.isEditOptionsDialogOpen = true;
        return;
      }
      this.processBookingForEditing();
    },
    checkIfDeleteOptionsToBeProvided() {
      if (this.isBookingIrregular || this.isBookingRegular) {
        this.isDeleteOptionsDialogOpen = true;
      } else if (this.askForReason) {
        this.deleteBookingWithReason(
          this.$t('bookingDelete.title'),
          this.$t('bookingDelete.bookingDeleteMessage', {
            creatorName: this.getCreatorName,
          }),
        );
      } else {
        this.deleteBooking();
      }
    },
    closeMenu() {
      this.$store.dispatch('closeDisplayMenu');
    },
    deleteGroupedBooking(selection) {
      if (this.isBookingIrregular) {
        this.deleteIrregularBooking(selection);
      } else {
        this.deleteRegularBooking(selection);
      }
    },

    keyDownHandler(event) {
      if (event.code === 'Escape') {
        this.closeMenu();
      }
    },
  },
};
</script>
