<template>
  <v-menu
    v-model="isOpen"
    absolute
    :position-x="menuPosition.x"
    :position-y="menuPosition.y"
    :close-on-content-click="false"
  >
    <v-card width="500">
      <add-edit-toolbar
        :title="$t('facilityBooking.addBooking')"
        :is-edit-mode="false"
        :is-paste-possible="isPasteAllowed"
        @clicked-paste="pasteBooking"
        @clicked-close="isOpen = false"
      />

      <v-card-text class="py-0">
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="booking.subject"
            prepend-icon="mdi-calendar-edit"
            :label="$t('facilityBooking.subject')"
            maxlength="255"
            :rules="[() => !!booking.subject || $t('common.required')]"
          />
          <div class="d-flex align-center">
            <date-time-range-picker
              :value="booking"
              :only-date="booking.allDay === 1"
              @input="changeDateTime"
            />
            <v-btn
              v-if="booking.allDay === 1"
              outlined
              height="40"
              @click="booking.allDay = 0"
              >{{ $t('event.addTime') }}</v-btn
            >
            <v-btn v-else icon @click="booking.allDay = 1">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-row
            v-if="dateTimeError.isError"
            class="red--text"
            justify="center"
          >
            {{ dateTimeError.message }}
          </v-row>
          <v-textarea
            v-model="booking.description"
            :label="$t('facilityBooking.description')"
            prepend-icon="mdi-text"
            auto-grow
            :rows="1"
          />
          <event-type-selector v-model="booking.eventTypeId" />
          <facilities-selector
            v-model="booking.facilities"
            class="mt-5 mb-3"
            :facilities-busy="facilitiesBusy"
            :required="true"
            @changed-facilities="fetchIsBookingValid"
          />
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="openMoreOptions">
          {{ $t('facilityBooking.moreOptions') }}
        </v-btn>
        <v-btn :loading="isLoading" color="primary" @click="validateAndSubmit">
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import DateTimeRangePicker from '../../../components/DateTimePicker/DateTimeRangePicker.vue';
import FacilitiesSelector from '../../../components/Selectors/FacilitiesSelector.vue';
import AddEditToolbar from '../../../components/Calendar/AddEditToolbar.vue';
import EventTypeSelector from '../../../components/EventTypeSelector.vue';

import facilityAddEdit from '../../../mixins/facilityAddEdit';
import dateTimeHelpers from '../../../mixins/dateTimeHelpers';

export default {
  components: {
    DateTimeRangePicker,
    AddEditToolbar,
    FacilitiesSelector,
    EventTypeSelector,
  },
  mixins: [facilityAddEdit, dateTimeHelpers],
  data() {
    return {
      isOpen: false,
      menuPosition: { x: null, y: null },
    };
  },
  computed: {
    ...mapGetters(['quickBookingInfo']),
  },
  watch: {
    quickBookingInfo() {
      if (this.quickBookingInfo !== null) {
        setTimeout(() => {
          this.setInitialBookingData();
          this.openMenuAndSetPosition();
        }, 1);
      }
    },
    isOpen() {
      if (!this.isOpen) {
        this.closeMenu();
      }
    },
  },
  methods: {
    setInitialBookingData() {
      this.facilitiesWithOverlappedTimePairs = null;
      this.booking = { ...this.defaultBooking };
      this.booking.facilities = [...this.quickBookingInfo.facilities];
      this.booking.start = `${this.quickBookingInfo.start} 09:00`;
      this.booking.end = `${this.quickBookingInfo.end} 10:00`;
      this.fetchIsBookingValid();
    },
    openMenuAndSetPosition() {
      this.isOpen = true;
      this.menuPosition.x = this.quickBookingInfo.x;
      this.menuPosition.y = this.quickBookingInfo.y;
    },
    openMoreOptions() {
      this.$store.dispatch('setAddEditBooking', {
        ...this.booking,
        ...{
          groupType: '',
          groupId: 0,
          irregularId: 0,
          recurrence: null,
        },
      });
      this.$store.dispatch('openFullAddEditBooking');
    },
    closeMenu() {
      this.$refs.form.resetValidation();
      this.$store.dispatch('resetGrid');
      this.$store.dispatch('resetQuickBookingInfo');
    },
  },
};
</script>
