<template>
  <v-dialog v-model="isOpen" width="350">
    <v-card>
      <v-card-title class="headline">
        {{ $t('dragDialogOptions.title') }}
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="option" hide-details>
          <v-radio
            v-for="(option, index) in options"
            :key="index"
            :label="option.message"
            :value="option.value"
          />
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="isOpen = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          :disabled="option === null"
          color="primary"
          outlined
          @click="storeDragDropOption"
        >
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isOpen: false,
      options: null,
      option: null,
    };
  },
  computed: {
    ...mapGetters(['dragDropOptions']),
  },
  watch: {
    dragDropOptions() {
      if (this.dragDropOptions !== null) {
        this.isOpen = true;
        this.options = { ...this.dragDropOptions };
      }
    },
    isOpen() {
      if (!this.isOpen) {
        this.$store.dispatch('updateIsRefreshRequired', true);
        this.refreshData();
      }
    },
  },
  methods: {
    storeDragDropOption() {
      this.$store.dispatch('storeSelectedDragDropOption', this.option);
      this.isOpen = false;
    },
    refreshData() {
      this.options = null;
      this.option = null;
    },
  },
};
</script>
