<template>
  <v-row no-gutters :style="{ height: rowHeight }" class="mt-0">
    <v-col
      v-for="(cell, index) in cells"
      :key="index"
      :style="{ width: `${100 / cells.length}%` }"
    >
      <v-card
        v-if="cell.type === 'information'"
        outlined
        tile
        :style="{ height: '100%', 'border-width': '0 0 1px 1px' }"
        @click="$store.dispatch('storeClickedNameCellInfo', cell.data)"
      >
        <slot name="information-card" :data="cell.data">
          {{ cell.data.name }}
        </slot>
      </v-card>
      <cell
        v-else
        :cell="cell"
        :row-id="rowId"
        :row-first-date="firstRowDate"
        :row-last-date="lastRowDate"
        @drag-change="emitDragChange($event, cell.date)"
      />
    </v-col>
  </v-row>
</template>

<script>
import Cell from './Cell.vue';
import { EVENT_HEIGHT, DEFAULT_ROW_HEIGHT } from './gridConstants';

export default {
  components: {
    Cell,
  },
  props: {
    rowItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    cells() {
      const cells = [];
      cells.push({
        type: 'information',
        data: this.rowItem.info,
      });
      const keys = this.rowItemDates;
      keys.forEach((key) => {
        cells.push({
          type: 'date',
          date: key,
          data: this.rowItem[key],
        });
      });
      return cells;
    },
    rowHeight() {
      const eventDepth = this.maxEventIndex + 1;
      const height = Math.max(
        (eventDepth + 2) * EVENT_HEIGHT,
        DEFAULT_ROW_HEIGHT,
      );
      return `${height}px`;
    },
    maxEventIndex() {
      let maxIndex = -1;
      this.cells.forEach((cell) => {
        if (cell.type === 'information' || cell.data.length === 0) {
          return;
        }
        cell.data.forEach((event) => {
          maxIndex = Math.max(maxIndex, event.index);
        });
      });
      return maxIndex;
    },
    rowItemDates() {
      const keys = Object.keys(this.rowItem);
      const dates = keys.filter((key) => key !== 'info');
      dates.sort();
      return dates;
    },
    firstRowDate() {
      const date = this.rowItemDates[0];
      return App.helpers.getDateObject(date);
    },
    lastRowDate() {
      const date = this.rowItemDates[this.rowItemDates.length - 1];
      return App.helpers.getDateObject(date);
    },
    rowId() {
      return this.cells[0].data.id;
    },
  },
};
</script>
