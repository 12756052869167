import { format } from 'date-fns';
import { mapGetters } from 'vuex';
import App from '../globalAppObject';

const bookingStoreAndUpdate = {
  computed: {
    ...mapGetters(['id']),
  },
  methods: {
    async storeBooking() {
      try {
        const start = App.helpers.getDateObject(this.booking.start);
        const end = App.helpers.getDateObject(this.booking.end);
        const { recurrence } = this.booking;
        if (recurrence === null) {
          await this.storeNormalBooking(start, end);
        } else if (recurrence.type === 'irregular_recurrence') {
          await this.storeIrregularRecurringBooking();
        } else if (recurrence.type === 'regular_recurrence') {
          await this.storeRecurringBooking(start, end);
        }
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityBooking.bookingAddedSuccessfully'),
        });
        this.$emit('booking-added-or-updated');
        this.isOpen = false;
      } catch (error) {
        if (error.response.data.errors.facilitiesTimeCheck !== null) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('messages.facilityHasBookingInTime'),
          });
        } else {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        }
        this.fetchIsBookingValid();
      }
    },
    async storeNormalBooking(start, end) {
      await axios.post('/api/facility-booking', {
        group_type: 'App\\Booking',
        group_id: this.booking.groupId,
        subject: this.booking.subject,
        description: this.booking.description,
        start: format(start, 'yyyy-MM-dd HH:mm:ss'),
        end: format(end, 'yyyy-MM-dd HH:mm:ss'),
        all_day: this.booking.allDay,
        event_type_id: this.booking.eventTypeId,
        facilities: this.booking.facilities,
        user_id: this.id,
      });
    },
    async storeIrregularRecurringBooking() {
      await axios.post('/api/facility-booking/recurrence-irregular/', {
        group_type: 'App\\Booking',
        group_ids: [this.booking.groupId],
        subject: this.booking.subject,
        description: this.booking.description,
        times: this.getTimePairsForIrregularRecurring(),
        all_day: this.booking.allDay,
        event_type_id: this.booking.eventTypeId,
        facilities: this.booking.facilities,
        user_id: this.id,
      });
    },
    async storeRecurringBooking(start, end) {
      await axios.post('/api/facility-booking/recurrence-regular/', {
        group_type: 'App\\BookingRecur',
        group_id: this.booking.groupId,
        subject: this.booking.subject,
        description: this.booking.description,
        start: format(start, 'yyyy-MM-dd HH:mm:ss'),
        end: format(end, 'yyyy-MM-dd HH:mm:ss'),
        all_day: this.booking.allDay,
        event_type_id: this.booking.eventTypeId,
        facilities: this.booking.facilities,
        user_id: this.id,
        freq: this.booking.recurrence.data.freq,
        count: this.booking.recurrence.data.count,
        until: this.booking.recurrence.data.until,
      });
    },
    async updateBooking() {
      try {
        const start = App.helpers.getDateObject(this.booking.start);
        const end = App.helpers.getDateObject(this.booking.end);
        const { recurrence } = this.booking;
        if (recurrence === null) {
          await this.updateNormalBooking(start, end);
        } else if (recurrence.type === 'irregular_recurrence') {
          await this.updateIrregularRecurringBooking();
        } else if (recurrence.type === 'regular_recurrence') {
          await this.updateRecurringBooking(start, end);
        }
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityBooking.bookingEditedSuccessfully'),
        });
        this.$emit('booking-added-or-updated');
        this.isOpen = false;
      } catch (error) {
        if (error.response.data.errors.facilitiesTimeCheck !== null) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('messages.facilityHasBookingInTime'),
          });
        } else {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        }
        this.fetchIsBookingValid();
      }
    },
    async updateNormalBooking(start, end) {
      await axios.patch(`/api/facility-booking/`, {
        group_type: this.booking.groupType,
        group_id: this.booking.groupId,
        subject: this.booking.subject,
        description: this.booking.description,
        start: format(start, 'yyyy-MM-dd HH:mm:ss'),
        end: format(end, 'yyyy-MM-dd HH:mm:ss'),
        all_day: this.booking.allDay,
        event_type_id: this.booking.eventTypeId,
        facilities: this.booking.facilities,
        user_id: this.id,
      });
    },
    async updateIrregularRecurringBooking() {
      const groupIds = [...this.booking.recurrence.data.groupIds];
      groupIds.push(this.booking.groupId);
      await axios.patch(`/api/facility-booking/irregular-recurring/`, {
        group_type: this.booking.groupType,
        group_ids: groupIds,
        subject: this.booking.subject,
        description: this.booking.description,
        times: this.getTimePairsForIrregularRecurring(),
        all_day: this.booking.allDay,
        event_type_id: this.booking.eventTypeId,
        facilities: this.booking.facilities,
        user_id: this.id,
      });
    },
    async updateRecurringBooking(start, end) {
      await axios.patch('/api/facility-booking/recurring/', {
        group_type: this.booking.groupType,
        group_id: this.booking.groupId,
        subject: this.booking.subject,
        description: this.booking.description,
        start: format(start, 'yyyy-MM-dd HH:mm:ss'),
        end: format(end, 'yyyy-MM-dd HH:mm:ss'),
        all_day: this.booking.allDay,
        event_type_id: this.booking.eventTypeId,
        facilities: this.booking.facilities,
        user_id: this.id,
        freq: this.booking.recurrence.data.freq,
        count: this.booking.recurrence.data.count,
        until: this.booking.recurrence.data.until,
      });
    },
  },
};

export default bookingStoreAndUpdate;
