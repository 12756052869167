<template>
  <week-view-grid :headers="headers" :items="facilitiesBookings">
    <template #information-card="{ data }">
      <div
        class="py-2 pl-1 d-flex justify-space-between flex-column"
        :style="{
          height: '100%',
          width: '100%',
          backgroundColor: '#F1F8E9',
        }"
      >
        <span :style="{ fontSize: namesFontSize }">
          {{ data.name }}
        </span>
        <span
          class="grey--text text--darken-2"
          :style="{ fontSize: departmentsFontSize }"
        >
          {{ facilityDepartments(data.departments) }}
        </span>
      </div>
    </template>
  </week-view-grid>
</template>

<script>
import { mapGetters } from 'vuex';
import { isSameDay, startOfWeek, format } from 'date-fns';
import WeekViewGrid from '../../../components/Grid/WeekViewGrid.vue';
import axiosCancelRequests from '../../../mixins/axiosCancelRequest';
import bookingDragDrop from '../../../mixins/bookingDragDrop';

import dataRefresher from '../../../mixins/dataRefresher';

export default {
  components: {
    WeekViewGrid,
  },

  mixins: [axiosCancelRequests, bookingDragDrop, dataRefresher],

  props: {
    facilityTypeId: {
      type: Number,
      required: true,
    },
    date: Date,
  },

  data: () => ({
    allFacilitiesBookings: [],
  }),

  computed: {
    ...mapGetters(['id', 'isGridLoading', 'firstDayOfTheWeek']),
    headers() {
      return [this.$t('facilityBooking.facilities'), ...this.getWeek];
    },
    getWeek() {
      const week = [];
      const weekStartsOn = this.firstDayOfTheWeek === 'sunday' ? 0 : 1;
      const date = startOfWeek(this.date, { weekStartsOn });

      for (let i = 0; i < 7; i += 1) {
        week.push(format(date, 'yyyy-MM-dd'));
        date.setDate(date.getDate() + 1);
      }

      return week;
    },
    namesFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '9px';
        default:
          return '16px';
      }
    },
    departmentsFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '8px';
        default:
          return '12px';
      }
    },
    facilitiesBookings() {
      return this.facilityTypeId === 0
        ? [...this.allFacilitiesBookings]
        : this.allFacilitiesBookings.filter(
            (facility) =>
              facility.info.facility_type_id === this.facilityTypeId,
          );
    },
  },

  watch: {
    date(newValue, oldValue) {
      if (!isSameDay(oldValue, newValue)) {
        this.$store.dispatch('showGridLoading');
        this.getAllBookingsForWeek();
      }
    },
  },

  mounted() {
    this.setUpRefresher(this.getAllBookingsForWeek);
  },

  created() {
    this.getAllBookingsForWeek();
  },

  methods: {
    async getAllBookingsForWeek() {
      this.cancelExistingRequests();
      const response = await axios.get('/api/facility-booking', {
        params: {
          week: this.getWeek,
        },
        cancelToken: this.getCancelToken(),
      });
      this.allFacilitiesBookings = response.data;
      this.$store.dispatch('resetGrid');
    },
    facilityDepartments(departments) {
      const len = departments.length;
      if (len === 0) {
        return '';
      }
      const firstDepartment = departments[0].name;
      return len === 1
        ? firstDepartment
        : this.$t('facilityBooking.facilityDepartment', {
            department: firstDepartment,
            count: len - 1,
          });
    },
  },
};
</script>
