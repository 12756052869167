<template>
  <div>
    <v-radio-group v-model="recurringType" row>
      <v-radio
        v-if="
          recurrenceTypeAllowed === null || recurrenceTypeAllowed === 'none'
        "
        :label="$t('repeatOptions.doesNot')"
        value="none"
      />
      <v-radio
        v-if="
          recurrenceTypeAllowed === null ||
          recurrenceTypeAllowed === 'irregular_recurrence'
        "
        :disabled="!isRecurrenceAllowed"
        :label="$t('repeatOptions.irregular')"
        value="irregular_recurrence"
      />
      <v-radio
        v-if="
          recurrenceTypeAllowed === null ||
          recurrenceTypeAllowed === 'regular_recurrence'
        "
        :disabled="!isRecurrenceAllowed"
        :label="$t('repeatOptions.regular')"
        value="regular_recurrence"
      />
    </v-radio-group>
    <v-tabs-items v-model="recurringType" class="px-10">
      <v-tab-item value="irregular_recurrence">
        <irregular-date-selector
          v-model="irregularData"
          :is-reset-needed="isResetNeeded"
          :error="error"
        />
      </v-tab-item>
      <v-tab-item value="regular_recurrence">
        <regular-date-selector
          v-model="regularOptions"
          :is-reset-needed="isResetNeeded"
          :error="error"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RegularDateSelector from './RegularDateSelector.vue';
import IrregularDateSelector from './IrregularDateSelector.vue';

export default {
  components: {
    RegularDateSelector,
    IrregularDateSelector,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    isResetNeeded: {
      type: Boolean,
      default: false,
    },
    recurrenceTypeAllowed: {
      type: String,
      default: null,
    },
    error: {
      type: Object,
      default() {
        return {
          isError: false,
          datesNotAllowed: [],
        };
      },
    },
  },
  data: () => ({
    latestRegular: null,
    latestIrregular: null,
  }),
  computed: {
    ...mapGetters([
      'isRecurrenceAllowed',
      'defaultIrregularRecurrence',
      'defaultRegularRecurrence',
    ]),
    recurringType: {
      get() {
        return this.value === null ? 'none' : this.value.type;
      },
      set(value) {
        this.emitInputBasedOnRecurrenceType(value);
      },
    },
    irregularData: {
      get() {
        return this.value === null || this.value.type === 'regular_recurrence'
          ? this.latestIrregular
          : this.value.data;
      },
      set(value) {
        if (this.recurringType === 'irregular_recurrence') {
          this.latestIrregular = { ...value };
          this.inputIrregularRecurrence();
        }
      },
    },
    regularOptions: {
      get() {
        return this.value === null || this.value.type === 'irregular_recurrence'
          ? this.latestRegular
          : this.value.data;
      },
      set(value) {
        if (this.recurringType === 'regular_recurrence') {
          this.latestRegular = { ...value };
          this.inputRegularRecurrence();
        }
      },
    },
  },
  watch: {
    isRecurrenceAllowed() {
      if (!this.isRecurrenceAllowed) {
        this.recurringType = 'none';
      }
    },
    isResetNeeded() {
      if (this.isResetNeeded) {
        this.reset();
      }
    },
  },
  created() {
    this.reset();
  },
  methods: {
    emitInputBasedOnRecurrenceType(type) {
      switch (type) {
        case 'irregular_recurrence':
          this.inputIrregularRecurrence();
          break;
        case 'regular_recurrence':
          this.inputRegularRecurrence();
          break;
        default:
          this.$emit('input', null);
      }
    },
    inputIrregularRecurrence() {
      const data =
        this.latestIrregular === null
          ? { ...this.defaultIrregularRecurrence }
          : { ...this.latestIrregular };
      this.$emit('input', { type: 'irregular_recurrence', data });
    },
    inputRegularRecurrence() {
      const data =
        this.latestRegular === null
          ? { ...this.defaultRegularRecurrence }
          : { ...this.latestRegular };
      this.$emit('input', { type: 'regular_recurrence', data });
    },
    reset() {
      this.latestIrregular = null;
      this.latestRegular = null;
    },
  },
};
</script>
