<template>
  <v-bottom-sheet v-model="isOpen" scrollable fullscreen>
    <v-card elevation-0 :ripple="false">
      <add-edit-toolbar
        :title="
          isEditing
            ? $t('facilityBooking.editBooking')
            : $t('facilityBooking.addBooking')
        "
        :is-edit-mode="isEditing"
        :is-paste-possible="isPasteAllowed"
        :is-full-screen="true"
        :is-submit-disabled="isSubmitDisabled || dateTimeError.isError"
        @clicked-paste="pasteBooking"
        @clicked-close="isOpen = false"
        @clicked-submit="validateAndSubmit"
      />
      <v-card-text class="pb-0" height="600px">
        <v-container>
          <v-row justify="center">
            <v-form ref="form" lazy-validation style="width: 500px">
              <v-text-field
                v-model="booking.subject"
                prepend-icon="mdi-calendar-edit"
                :label="$t('facilityBooking.subject')"
                maxlength="255"
                :rules="[() => !!booking.subject || $t('common.required')]"
                style="max-width: 400px"
              />
              <v-checkbox
                v-model="booking.allDay"
                :label="$t('event.all_day')"
                hide-details
              />
              <date-time-range-picker
                :value="booking"
                :only-date="booking.allDay === 1 || booking.allDay === true"
                @input="changeDateTime"
              />
              <v-row
                v-if="dateTimeError.isError"
                class="red--text"
                justify="center"
              >
                {{ dateTimeError.message }}
              </v-row>
              <v-row
                v-if="error.isErrorPresent"
                class="red--text"
                justify="center"
              >
                {{ error.errorMessage }}
              </v-row>
              <recurring-date-selector
                v-model="booking.recurrence"
                :is-reset-needed="isResetNeeded"
                :recurrence-type-allowed="recurrenceTypeAllowed"
                :error="recurrenceError"
                @input="fetchIsBookingValid"
              />
              <v-divider />
              <v-textarea
                v-model="booking.description"
                prepend-icon="mdi-text"
                :label="$t('facilityBooking.description')"
                auto-grow
                :rows="1"
              />
              <event-type-selector v-model="booking.eventTypeId" />
              <facilities-selector
                v-model="booking.facilities"
                class="mt-5"
                :facilities-busy="facilitiesBusy"
                :required="true"
                @changed-facilities="fetchIsBookingValid"
              />
            </v-form>
          </v-row>
        </v-container>
      </v-card-text>

      <add-edit-actions
        :is-submit-disabled="isSubmitDisabled || dateTimeError.isError"
        @clicked-cancel="isOpen = false"
        @clicked-submit="validateAndSubmit"
      />
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import DateTimeRangePicker from '../../../components/DateTimePicker/DateTimeRangePicker.vue';
import AddEditToolbar from '../../../components/Calendar/AddEditToolbar.vue';
import AddEditActions from '../../../components/Calendar/AddEditActions.vue';
import FacilitiesSelector from '../../../components/Selectors/FacilitiesSelector.vue';
import EventTypeSelector from '../../../components/EventTypeSelector.vue';
import RecurringDateSelector from '../../../components/RecurringDateSelector/RecurringDateSelector.vue';

import facilityAddEdit from '../../../mixins/facilityAddEdit';

export default {
  components: {
    DateTimeRangePicker,
    AddEditToolbar,
    AddEditActions,
    FacilitiesSelector,
    EventTypeSelector,
    RecurringDateSelector,
  },
  mixins: [facilityAddEdit],
  data: () => ({
    isSubmitDisabled: false,
    isResetNeeded: false,
    recurrenceTypeAllowed: null,
  }),
  computed: {
    ...mapGetters(['isFullAddEditBookingOpen', 'addEditBooking']),
    isOpen: {
      get() {
        return this.isFullAddEditBookingOpen;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
  },
  watch: {
    isOpen() {
      if (!this.isOpen) {
        return;
      }
      this.isResetNeeded = false;
      if (this.addEditBooking.id !== 0) {
        this.setRecurrenceType(this.addEditBooking.recurrence);
      }
      setTimeout(() => {
        this.booking = { ...this.addEditBooking };
        this.$store.dispatch('resetAddEditBooking');
        this.fetchIsBookingValid();
      }, 20);
    },
  },

  methods: {
    setRecurrenceType(recurrence) {
      this.recurrenceTypeAllowed =
        recurrence === null ? 'none' : recurrence.type;
    },
    closeDialog() {
      this.booking = { ...this.defaultBooking };
      this.recurrenceTypeAllowed = null;
      this.isResetNeeded = true;
      this.$refs.form.resetValidation();
      this.$store.dispatch('closeFullAddEditBooking');
      this.$store.dispatch('resetRecurringDateSelector');
    },
  },
};
</script>
