<template>
  <v-container>
    <v-select
      v-model="freq"
      :items="repeatOptions"
      hide-details
      item-text="name"
      item-value="value"
      prepend-icon="mdi-format-list-bulleted"
      dense
      outlined
    />
    <v-radio-group v-model="tabs" row>
      <v-radio :label="$t('event.untilDate')" value="until" />
      <v-radio :label="$t('event.noOfRepeats')" value="count" />
    </v-radio-group>

    <v-tabs-items v-model="tabs" class="pt-1">
      <v-tab-item value="until">
        <v-menu
          v-model="isUntilDateMenuOpen"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="until"
              outlined
              dense
              readonly
              hide-details
              :label="$t('event.pickUntil')"
              prepend-icon="mdi-calendar-range"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="until"
            :allowed-dates="getAllowedDates"
            no-title
            :locale="$i18n.locale"
          />
        </v-menu>
      </v-tab-item>
      <v-tab-item value="count">
        <v-text-field
          v-model="count"
          outlined
          dense
          hide-details
          prepend-icon="mdi-counter"
          :label="$t('event.noOfRepeats')"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-alert v-if="error.isError === true" class="mt-2" type="error">
      {{ $t('messages.regularRecurringOptionsError') }}
    </v-alert>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    isResetNeeded: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default() {
        return {
          isError: false,
          datesNotAllowed: [],
        };
      },
    },
  },

  data() {
    return {
      latestFreq: 'DAILY',
      latestTab: 'until',
      latestUntil: null,
      latestCount: null,
      isUntilDateMenuOpen: false,
    };
  },

  computed: {
    ...mapGetters([
      'recurringEventStart',
      'recurringEventMinutesDuration',
      'defaultUntilForFreq',
      'defaultCountForFreq',
    ]),
    repeatOptions() {
      return [
        { name: this.$t('event.daily'), value: 'DAILY' },
        { name: this.$t('event.weekly'), value: 'WEEKLY' },
        { name: this.$t('event.monthly'), value: 'MONTHLY' },
        { name: this.$t('event.yearly'), value: 'YEARLY' },
      ];
    },

    freq: {
      get() {
        return this.value === null ? 'DAILY' : this.value.freq;
      },
      set(value) {
        this.latestFreq = value;
        this.latestUntil = this.defaultUntilForFreq(value);
        this.latestCount = this.defaultCountForFreq(value);
        this.emitInputBasedOnUntilOrCountTab(this.tabs);
      },
    },
    tabs: {
      get() {
        return this.value === null || this.value.count === null
          ? 'until'
          : 'count';
      },
      set(value) {
        this.latestUntil = this.getLatestUntilForFreq();
        this.latestCount = this.getLatestCountForFreq();
        this.emitInputBasedOnUntilOrCountTab(value);
      },
    },
    until: {
      get() {
        return this.getLatestUntilForFreq();
      },
      set(value) {
        if (this.tabs === 'until') {
          this.latestUntil = value;
          this.inputUntil();
        }
      },
    },
    count: {
      get() {
        return this.getLatestCountForFreq();
      },
      set(value) {
        if (this.tabs === 'count') {
          this.latestCount = value;
          this.inputCount();
        }
      },
    },
  },
  watch: {
    isResetNeeded() {
      if (this.isResetNeeded) {
        this.setDefaultValues();
      }
    },
  },
  created() {
    this.setDefaultValues();
  },
  methods: {
    setDefaultValues() {
      this.reset();
    },
    getAllowedDates(value) {
      return value > this.recurringEventStart.slice(0, 10);
    },
    getLatestUntilForFreq() {
      if (this.value !== null && this.value.until !== null) {
        return this.value.until;
      }
      return this.latestUntil !== null
        ? this.latestUntil
        : this.defaultUntilForFreq(this.freq);
    },
    getLatestCountForFreq() {
      if (this.value !== null && this.value.count !== null) {
        return this.value.count;
      }
      return this.latestCount !== null
        ? this.latestCount
        : this.defaultCountForFreq(this.freq);
    },
    emitInputBasedOnUntilOrCountTab(tab) {
      if (tab === 'until') this.inputUntil();
      else this.inputCount();
    },
    inputUntil() {
      this.$emit('input', {
        freq: this.latestFreq,
        until: this.latestUntil,
        count: null,
      });
    },
    inputCount() {
      this.$emit('input', {
        freq: this.latestFreq,
        until: null,
        count: this.latestCount,
      });
    },
    reset() {
      this.latestFreq = 'DAILY';
      this.latestTab = 'until';
      this.latestUntil = null;
      this.latestCount = null;
      this.isUntilDateMenuOpen = false;
    },
  },
};
</script>
