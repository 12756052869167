<template>
  <v-container>
    <v-menu
      ref="irregularMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template #activator="{ on }">
        <v-combobox
          v-model="dates"
          :label="$t('event.additionalDates')"
          prepend-icon="mdi-calendar-range"
          dense
          outlined
          multiple
          readonly
          v-on="on"
        >
          <template #selection="{ item }">
            <v-chip
              small
              :color="chipColor(item)"
              close
              @click:close="removeDate(item)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
      </template>
      <v-date-picker
        v-model="dates"
        :allowed-dates="restrictSelectingCurrentDate"
        no-title
        first-day-of-week="1"
        :locale="$i18n.locale"
        multiple
      />
    </v-menu>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    isResetNeeded: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default() {
        return {
          isError: false,
          datesNotAllowed: [],
        };
      },
    },
  },
  data() {
    return {
      latestDates: [],
      latestGroupIds: [],
    };
  },

  computed: {
    ...mapGetters(['recurringEventStart', 'recurringEventMinutesDuration']),
    dates: {
      get() {
        return this.value === null ? [] : this.value.dates;
      },
      set(value) {
        this.latestDates = value;
        this.inputDates();
      },
    },
    groupIds() {
      if (this.value !== null) {
        this.latestGroupIds = this.value.groupIds;
      }
      return this.latestGroupIds;
    },
  },

  watch: {
    isResetNeeded() {
      if (this.isResetNeeded) {
        this.setDefaultValues();
      }
    },
  },
  created() {
    this.setDefaultValues();
  },
  methods: {
    setDefaultValues() {
      this.reset();
    },
    chipColor(date) {
      return this.error.datesNotAllowed.includes(date)
        ? 'red white--text'
        : 'grey lighten-2';
    },
    restrictSelectingCurrentDate(value) {
      return value !== this.recurringEventStart.slice(0, 10);
    },
    inputDates() {
      this.$emit('input', {
        dates: [...this.sortDates(this.latestDates)],
        groupIds: [...this.groupIds],
      });
    },
    removeDate(dateBeingRemoved) {
      this.dates = [...this.dates.filter((date) => date !== dateBeingRemoved)];
    },
    sortDates(dates) {
      dates.sort((a, b) => (a < b ? -1 : 1));
      return dates;
    },
    reset() {
      this.latestDates = [];
      this.latestGroupIds = [];
    },
  },
};
</script>
