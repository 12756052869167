const bookingEdit = {
  data() {
    return {
      irregularRecurrence: null,
    };
  },
  methods: {
    async editIrregularBooking(type) {
      let recurrenceData = await this.fetchIrregularBookingRecurrence();
      this.irregularRecurrence = {};
      if (type === 'self') {
        recurrenceData = [];
      } else if (type === 'further') {
        recurrenceData = recurrenceData.filter(
          (item) => item.start > this.displayEvent.start,
        );
      } else {
        recurrenceData = recurrenceData.filter(
          (item) => item.start !== this.displayEvent.start,
        );
      }
      this.irregularRecurrence.dates = recurrenceData.map((item) =>
        item.start.slice(0, 10),
      );
      this.irregularRecurrence.groupIds = recurrenceData.map(
        (item) => item.groupId,
      );
      this.processBookingForEditing();
    },
    async fetchIrregularBookingRecurrence() {
      const { data } = await axios.get(
        `/api/facility-booking/get-recurrence-data/${this.displayEvent.id}`,
      );
      return data.data;
    },
    processBookingForEditing() {
      this.$store.dispatch('setAddEditBooking', this.getFormattedBooking());
      this.$store.dispatch('openFullAddEditBooking');
    },
    getFormattedBooking() {
      const booking = {
        id: this.displayEvent.id,
        groupType: this.displayEvent.group_type,
        groupId: this.displayEvent.group_id,
        irregularId: this.displayEvent.irregular_id,
        subject: this.displayEvent.subject,
        description: this.displayEvent.description,
        allDay: this.displayEvent.all_day,
        start: this.displayEvent.start,
        end: this.displayEvent.end,
        eventTypeId: this.displayEvent.event_type_id,
        facilities: this.displayEvent.associated_facilities,
        recurrence: null,
      };
      if (this.isBookingRegular) {
        booking.recurrence = {
          type: 'regular_recurrence',
          data: {
            freq: this.displayEvent.freq,
            count: this.displayEvent.count,
            until: this.displayEvent.until,
          },
        };
      }
      if (this.isBookingIrregular) {
        booking.recurrence = {
          type: 'irregular_recurrence',
          data: {
            dates: this.irregularRecurrence.dates,
            groupIds: this.irregularRecurrence.groupIds,
          },
        };
      }
      return booking;
    },
  },
};

export default bookingEdit;
