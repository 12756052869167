<template>
  <div>
    <meeting-display-menu />
    <booking-display-menu />
    <event-display-menu />
    <task-display-menu v-if="temp" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MeetingDisplayMenu from './components/MeetingDisplayMenu.vue';
import BookingDisplayMenu from './components/BookingDisplayMenu.vue';
import EventDisplayMenu from './components/EventDisplayMenu.vue';
import TaskDisplayMenu from './TaskDisplayMenu.vue';

export default {
  components: {
    MeetingDisplayMenu,
    BookingDisplayMenu,
    EventDisplayMenu,
    TaskDisplayMenu,
  },
  data() {
    return {
      temp: false,
    };
  },
  computed: {
    ...mapGetters(['displayEvent', 'eventChipInfo']),
  },
  watch: {
    eventChipInfo() {
      if (this.eventChipInfo !== null) {
        this.$store.dispatch('setDisplayEvent', this.eventChipInfo.data);
        this.$store.dispatch('setDisplayPosition', {
          x: this.eventChipInfo.x,
          y: this.eventChipInfo.y,
        });
      }
    },
  },
};
</script>
