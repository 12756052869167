<template>
  <v-container class="pa-0 ma-0" fluid>
    <facility-toolbar
      :view="view"
      @facility-selected="facilitySelected"
      @facility-type-selected="facilityTypeSelected"
    />
    <facility-week-view
      v-if="view === 'week'"
      ref="weekView"
      :facility-type-id="facilityTypeId"
      :facility-id="facilityId"
      :date="App.helpers.getDateObject(currentDate)"
    />
    <facility-month-view
      v-if="view === 'month'"
      ref="monthView"
      :date="App.helpers.getDateObject(currentDate)"
      :facility-id="facilityId"
    />
    <drag-options-dialog />
    <quick-add-booking
      @booking-added-or-updated="
        $store.dispatch('updateIsRefreshRequired', true)
      "
    />
    <full-add-edit-booking
      @booking-added-or-updated="
        $store.dispatch('updateIsRefreshRequired', true)
      "
    />
    <display-menu />
    <facility-display />
    <confirm ref="confirm" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import FacilityToolbar from './components/FacilityToolbar.vue';
import FacilityWeekView from './components/FacilityWeekView.vue';
import FacilityMonthView from './components/FacilityMonthView.vue';
import QuickAddBooking from './components/QuickAddBooking.vue';
import FullAddEditBooking from './components/FullAddEditBooking.vue';
import FacilityDisplay from './components/FacilityDisplay.vue';
import Confirm from '../../components/Confirm.vue';
import DragOptionsDialog from '../../components/Grid/DragOptionsDialog.vue';
import DisplayMenu from '../../components/DisplayMenu/DisplayMenu.vue';

export default {
  components: {
    FacilityToolbar,
    FacilityWeekView,
    FacilityMonthView,
    QuickAddBooking,
    Confirm,
    FullAddEditBooking,
    FacilityDisplay,
    DragOptionsDialog,
    DisplayMenu,
  },

  beforeRouteLeave(from, to, next) {
    this.$store.dispatch('resetFacilityPageMenu');
    next();
  },
  data() {
    return {
      view: null,
      facilityId: 0,
      facilityTypeId: 0,
    };
  },
  computed: {
    ...mapGetters([
      'userData',
      'currentDate',
      'clickInfo',
      'doubleClickInfo',
      'selectInfo',
      'eventChipInfo',
    ]),
  },
  watch: {
    $route() {
      this.initialize();
    },
    clickInfo() {
      if (this.clickInfo !== null) {
        if (this.$vuetify.breakpoint.xsOnly) {
          this.openFullAddEditBooking(
            [this.clickInfo.row],
            this.clickInfo.col,
            this.clickInfo.col,
          );
          return;
        }
        this.$store.dispatch('storeQuickBookingInfo', {
          facilities: [this.clickInfo.row],
          start: this.clickInfo.col,
          end: this.clickInfo.col,
          x: this.clickInfo.x,
          y: this.clickInfo.y,
        });
      }
    },
    doubleClickInfo() {
      if (this.doubleClickInfo !== null) {
        this.openFullAddEditBooking(
          [this.doubleClickInfo.id],
          this.doubleClickInfo.date,
          this.doubleClickInfo.date,
        );
      }
    },
    selectInfo() {
      if (this.selectInfo !== null) {
        if (this.$vuetify.breakpoint.xsOnly) {
          this.openFullAddEditBooking(
            [this.selectInfo.row],
            this.selectInfo.col.start,
            this.selectInfo.col.end,
          );
          return;
        }
        this.$store.dispatch('storeQuickBookingInfo', {
          facilities: [this.selectInfo.row],
          start: this.selectInfo.col.start,
          end: this.selectInfo.col.end,
          x: this.selectInfo.x,
          y: this.selectInfo.y,
        });
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    openFullAddEditBooking(facilities, startDate, endDate) {
      this.$store.dispatch('setAddEditBooking', {
        id: 0,
        groupType: '',
        groupId: 0,
        irregularId: 0,
        subject: '',
        description: '',
        allDay: 0,
        start: `${startDate} 09:00`,
        end: `${endDate} 10:00`,
        eventTypeId: null,
        facilities,
        recurrence: null,
      });
      this.$store.dispatch('openFullAddEditBooking');
      this.$store.dispatch('resetGrid');
    },
    initialize() {
      this.$store.dispatch('showGridLoading');
      [, this.view] = this.$route.name.split('-');
    },
    facilitySelected(facility) {
      this.facilityId = facility;
    },
    facilityTypeSelected(facilityType) {
      this.facilityTypeId = facilityType;
    },
  },
};
</script>
