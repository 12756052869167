var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.recurringType),callback:function ($$v) {_vm.recurringType=$$v},expression:"recurringType"}},[(
        _vm.recurrenceTypeAllowed === null || _vm.recurrenceTypeAllowed === 'none'
      )?_c('v-radio',{attrs:{"label":_vm.$t('repeatOptions.doesNot'),"value":"none"}}):_vm._e(),(
        _vm.recurrenceTypeAllowed === null ||
        _vm.recurrenceTypeAllowed === 'irregular_recurrence'
      )?_c('v-radio',{attrs:{"disabled":!_vm.isRecurrenceAllowed,"label":_vm.$t('repeatOptions.irregular'),"value":"irregular_recurrence"}}):_vm._e(),(
        _vm.recurrenceTypeAllowed === null ||
        _vm.recurrenceTypeAllowed === 'regular_recurrence'
      )?_c('v-radio',{attrs:{"disabled":!_vm.isRecurrenceAllowed,"label":_vm.$t('repeatOptions.regular'),"value":"regular_recurrence"}}):_vm._e()],1),_c('v-tabs-items',{staticClass:"px-10",model:{value:(_vm.recurringType),callback:function ($$v) {_vm.recurringType=$$v},expression:"recurringType"}},[_c('v-tab-item',{attrs:{"value":"irregular_recurrence"}},[_c('irregular-date-selector',{attrs:{"is-reset-needed":_vm.isResetNeeded,"error":_vm.error},model:{value:(_vm.irregularData),callback:function ($$v) {_vm.irregularData=$$v},expression:"irregularData"}})],1),_c('v-tab-item',{attrs:{"value":"regular_recurrence"}},[_c('regular-date-selector',{attrs:{"is-reset-needed":_vm.isResetNeeded,"error":_vm.error},model:{value:(_vm.regularOptions),callback:function ($$v) {_vm.regularOptions=$$v},expression:"regularOptions"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }