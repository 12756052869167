<template>
  <div id="calendar-grid">
    <month-view-grid :row="facilityId" :current-date="date" :items="bookings" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { differenceInCalendarMonths, getDaysInMonth, format } from 'date-fns';
import MonthViewGrid from '../../../components/Grid/MonthViewGrid.vue';
import bookingDragDrop from '../../../mixins/bookingDragDrop';

import dataRefresher from '../../../mixins/dataRefresher';

export default {
  components: {
    MonthViewGrid,
  },
  mixins: [bookingDragDrop, dataRefresher],
  props: {
    facilityId: {
      type: Number,
      default: 0,
    },
    date: Date,
  },
  data: () => ({
    moreEventsData: {
      date: '',
      isMenuOpen: false,
      menuPosition: [0, 0],
      events: [],
    },

    bookings: [],
  }),
  computed: {
    ...mapGetters(['id']),
    getDurationForCurrentMonth() {
      const yearMonth = format(this.date, 'yyyy-MM');
      const start = `${yearMonth}-01 00:00:00`;
      const daysInMonth = getDaysInMonth(this.date);
      const end = `${yearMonth}-${daysInMonth} 23:59:59`;
      return { start, end };
    },
  },
  watch: {
    date(newValue, oldValue) {
      if (differenceInCalendarMonths(oldValue, newValue) !== 0) {
        this.$store.dispatch('showLinearLoading');
        this.getFacilityBookingsForMonth();
      }
    },
    facilityId() {
      this.$store.dispatch('showLinearLoading');
      this.getFacilityBookingsForMonth();
    },
  },

  mounted() {
    this.setUpRefresher(this.getFacilityBookingsForMonth);
  },

  created() {
    this.setDefaultForMoreMenu();
    this.getFacilityBookingsForMonth();
  },

  methods: {
    async getFacilityBookingsForMonth() {
      if (this.facilityId === 0) {
        this.$store.dispatch('resetGrid');
        return;
      }
      const { data } = await axios.get(
        `/api/facility-booking/bookings/${this.facilityId}`,
        {
          params: { ...this.getDurationForCurrentMonth },
        },
      );
      this.bookings = [...data];
      this.$store.dispatch('resetGrid');
    },
    displayMoreEvents(nativeEvent) {
      const { date } = nativeEvent;
      const events = this.events.filter(
        (event) =>
          event.start <= `${date} 23:59` && event.end >= `${date} 00:00`,
      );
      events.sort((a, b) => {
        if (a.start <= b.start) {
          return -1;
        }
        return 1;
      });
      this.moreEventsData = {
        date,
        events,
        menuPosition: [window.event.x, window.event.y],
        isMenuOpen: true,
      };
    },
    openQuickAddForMonth(dateEvent) {
      if (this.moreEventsData.isMenuOpen) {
        return;
      }
      if (this.facilityId === 0) {
        this.openSnackbar('error', this.$t('messages.facilityNotSelected'));
        return;
      }
      if (this.isPastDate(dateEvent.date)) {
        this.openSnackbar('error', this.$t('messages.dateNotAllowed'));
        return;
      }
      this.$emit(
        'open-quick-add',
        this.facilityId,
        dateEvent.date,
        window.event,
      );
    },

    openSnackbar(color, message) {
      this.$store.dispatch('openSnackbar', {
        color,
        timeout: 3000,
        message,
      });
    },

    setDefaultForMoreMenu() {
      this.moreEventsData.isMenuOpen = false;
      this.moreEventsData.date = '';
      this.moreEventsData.events = [];
    },
  },
};
</script>
