<template>
  <v-dialog
    v-model="isOpen"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    width="800px"
  >
    <v-card>
      <v-card-title class="headline">
        {{ name }}
        <v-spacer />
        <v-btn icon @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="pt-2" justify="center">
          <v-window v-model="currentImage">
            <v-window-item v-for="(url, index) in imagesUrl" :key="index">
              <v-img max-width="800px" max-height="400px" :src="url" contain />
            </v-window-item>
            <v-window-item v-if="imagesUrl.length == 0">
              <v-row justify="center" class="text-h5">
                {{ $t('facilityBooking.noImages') }}
              </v-row>
            </v-window-item>
          </v-window>
        </v-row>
        <v-row class="pb-2 pt-1" justify="center">
          <v-item-group v-model="currentImage" mandatory>
            <v-item
              v-for="(image, index) in imagesUrl"
              :key="index"
              v-slot="{ active, toggle }"
            >
              <v-btn x-small :input-value="active" icon @click="toggle">
                <v-icon> mdi-record </v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
        </v-row>

        <v-divider class="pb-4" />
        <v-row
          dense
          class="d-flex justify-space-between"
          align="center"
          no-gutters
        >
          <v-col sm="3" class="font-weight-bold" justify="center">
            {{ $t('facilityBooking.departmentsThatCanBook') }}
          </v-col>
          <div class="font-weight-bold">：</div>
          <v-col class="pl-3">
            <span v-if="isVisibleToAllDepartments">
              {{ $t('common.all') }}
            </span>
            <span v-if="!isVisibleToAllDepartments">
              {{ departments.join(', ') }}
            </span>
          </v-col>
        </v-row>
        <v-row
          v-for="(info, index) in infos"
          :key="index"
          dense
          class="d-flex justify-space-between"
          align="center"
          no-gutters
        >
          <v-col sm="3" class="font-weight-bold" justify="center">
            {{ info.category }}
          </v-col>
          <div class="font-weight-bold">：</div>
          <v-col class="pl-3">
            {{ info.categoryAnswer }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isOpen: false,
      facilityId: null,
      name: '',
      imagesUrl: [],
      imagesDetails: [],
      currentImage: 0,
      infos: [],
      isVisibleToAllDepartments: false,
      departments: [],
    };
  },

  computed: {
    ...mapGetters(['facility', 'nameCellInfo']),
  },

  watch: {
    nameCellInfo() {
      if (this.nameCellInfo !== null) {
        this.facilityId = this.nameCellInfo.id;
        this.getFacilityDetails();
        this.isOpen = true;
      }
    },
    isOpen() {
      if (!this.isOpen) {
        this.assignDefaultData();
        this.$store.dispatch('resetGrid');
      }
    },
  },
  methods: {
    assignDefaultData() {
      this.facilityId = null;
      this.name = '';
      this.imagesUrl = [];
      this.imagesDetails = [];
      this.currentImage = 0;
      this.infos = [];
      this.isVisibleToAllDepartments = false;
      this.departments = [];
    },
    async getFacilityDetails() {
      const response = await axios.get(`/api/facility/${this.facilityId}`);
      this.name = response.data.name;
      this.getFacilityVisibleToDepartment();
      this.getFacilityImages(response.data.urls);
      this.getExtraInfo(response.data.extra_info);
    },

    getFacilityVisibleToDepartment() {
      const facility = this.facility(this.facilityId);
      if (facility.is_visible_to_all_departments) {
        this.isVisibleToAllDepartments = true;
        return;
      }
      this.departments = facility.departments.map(
        (department) => department.name,
      );
    },

    getFacilityImages(images) {
      images.forEach((image) => {
        this.imagesUrl.push(image.url);
        this.imagesDetails.push(image.actual_name);
      });
    },
    getExtraInfo(infos) {
      if (infos != null) {
        const keys = Object.keys(infos);
        this.infos = [];
        keys.forEach((x) => {
          if (infos[x] !== null && infos[x] !== '') {
            this.infos.push({
              category: x,
              categoryAnswer: infos[x],
            });
          }
          return [];
        });
      }
    },
  },
};
</script>
